import React, {
  createElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "./editor.css";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import PanoramaOutlinedIcon from "@mui/icons-material/PanoramaOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import RoundedCornerOutlinedIcon from "@mui/icons-material/RoundedCornerOutlined";
import Design from "./components/Design/Design";
import { fabric } from "fabric";
import QRious from "qrious";
import Text from "./components/Text/Text";
import Picture from "./components/Picture/Picture";
import Video from "./components/Video/Video";
import Shape from "./components/Shape/Shape";
import EditorOps from "./EditorOps";
import ShapesProperty from "./components/Shape/ShapesProperty";
import CanvaProperty from "./CanvaProperty";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import TextTools from "./components/Text/TextTools";
import { resetTextFontIsChanged } from "../Redux/Actions/EditorAction";
import {
  CalendarDays,
  Clock,
  CloudSun,
  ListPlus,
  QrCode,
  Rss,
  ThermometerSun,
  Type,
} from "lucide-react";
import Time from "./components/Time/Time";
import DateWidget from "./components/Date/DateWidget";
import QRCode from "./components/QRCode/QRCode";
import videoPlayerThumbnail from "./video-player-thumbnail.png";
import {
  _addFromCanvaTemplate,
  _editToCanvaTemplate,
  addContent,
  contentFlagOff,
} from "../Redux/Actions/contentAction";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import PlaylistCanva from "./components/playlistcanva/PlaylistCanva";
import { ReactComponent as CDesign } from "../Assets/icons/design.svg";
import { ReactComponent as CText } from "../Assets/icons/text.svg";
import { ReactComponent as CImage } from "../Assets/icons/image.svg";
import { ReactComponent as CVideo } from "../Assets/icons/video.svg";
import { ReactComponent as CTime } from "../Assets/icons/time.svg";
import { ReactComponent as CDate } from "../Assets/icons/calendar.svg";
import { ReactComponent as CQrCode } from "../Assets/icons/qrcode.svg";
import { ReactComponent as CPlaylist } from "../Assets/icons/playlist.svg";
// import cvideo from "../Assets/icons/video.svg";
// import analogclock from "../Assets/images/analog-clock.jpg";
// import rss_icon from "../Assets/images/rss-icon.png";
// import video_icon from "../Assets/images/video-icon.png";
// import playlist_icon from "../Assets/images/playlist-video.png";
// import weather_icon from "../Assets/images/weather_icon.png";
// import temp_icon from "../../uploads/temp-icon.png";
import RssComponent from "./components/Rss/RssComponent";
import Weather from "./components/weather/Weather";
import TextStrip from "./components/textstrip/TextStrip";
import Temperature from "./components/Temprature/Temperature";
import ToolPlaylistOp from "./components/playlistcanva/ToolPlaylistOp";
import { api } from "../api/api";
const properties = {
  default: CanvaProperty,
  Shapes: ShapesProperty,
  TextTool: TextTools,
};
export default function DesignEditor() {
  const [searchParams] = useSearchParams();
  const _folderId = searchParams.get("_folderId");
  const { _textProps, _fontColor, isChanged } = useSelector(
    (state) => state.Editor
  );
  const { user, isContentAdded, isContentUpdated } = useSelector(
    (state) => ({
      user: state.User.user,
      isContentAdded: state.Content.isContentAdded,
      isContentUpdated: state.Content.isContentUpdated,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [layers, setLayers] = useState([]);
  const [objecKey, setObjectKey] = useState("default");
  const canvasRef = useRef(null);
  const [type, setType] = useState("Design");
  const [file, setFile] = useState("");
  // const [height, setHeight] = useState(1080);
  // const [width, setWidth] = useState(1920);
  const [pan, setPan] = useState(false);
  const [id, setId] = useState("");
  const [_textAlign, setTextAlign] = useState("");
  const [canvaProps, setCanvaProps] = useState({});
  const [textBoxProps, setTextBoxProps] = useState({});
  const [shapeProps, setShapeProps] = useState({});
  const [textTimeProps, setTextTimeProps] = useState({});
  const [textDateProps, setTextDateProps] = useState({});
  const [textStripProps, setTextStripProps] = useState({});
  const [weatherProps, setWeatherProps] = useState({});
  const [tempProps, setTempProps] = useState({});
  const [playlistProps, setPlaylistProps] = useState({});
  const [templateName, setTemplateName] = useState("");
  const [length, setLength] = useState({
    height: "",
    width: "",
  });
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [activeObj, setActiveObj] = useState({});
  const [canvaColor, setCanvaColor] = useState("white");
  const [zoom, setZoom] = useState(1);
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [canvaId, setCanvaId] = useState("");
  const isDragging = useRef(false);
  const [speedValue, setSpeedValue] = useState("");
  const [stripText, setStripText] = useState("");
  const [stripDirection, setStripDirection] = useState("");
  const [height, setHeight] = useState(1080);
  const [width, setWidth] = useState(1920);
  const [canvasLength, setCanvasLength] = useState({
    height: 1080,
    width: 1920,
  });
  const aspectRatio = useRef(width / height);
  const [scrollStrip, setScrollStrip] = useState({
    duration: "",
    text: "",
    direction: "",
  });
  const [qrProps, setQrProps] = useState({});
  const [localUrl, setLocalUrl] = useState(
    "https://signage.mdisplay.no/public/local"
  );
  const PropComponent = properties[objecKey];
  useEffect(() => {
    const container = document.querySelector("#outer-container");
    const canvas = new fabric.Canvas("canvas", {
      name: "canva-main",
      height: height,
      width: width,
      backgroundColor: "white",
      // borderRadius:'10px',
      selection: true,
      stopContextMenu: true,
      fireRightClick: true,
      preserveObjectStacking: true,
      enableRetinaScaling: true,
    });
    // canvas.setHeight(container.clientHeight - 150);
    // canvas.setWidth(container.clientWidth - 150);

    // setDimention({
    //   height: container.clientHeight - 150,
    //   width: container.clientWidth - 150,
    // });

    setCanvaProps({
      name: "canva-main",
      // height: 500,
      // width: 800,
      backgroundColor: "white",
      selection: true,
    });

    canvasRef.current = canvas;
    saveHistory(canvas);
    canvas.requestRenderAll();
    return () => {
      canvas.dispose();
    };
  }, []);

  useEffect(() => {
    if (canvasRef.current) {
      const scaleFactorX = width / canvasRef.current.width;
      const scaleFactorY = height / canvasRef.current.height;

      canvasRef.current.setDimensions({ width, height });

      // canvasRef.current.getObjects().forEach((obj) => {
      //   obj.scaleX *= scaleFactorX;
      //   obj.scaleY *= scaleFactorY;
      //   obj.left *= scaleFactorX;
      //   obj.top *= scaleFactorY;
      //   obj.setCoords();
      // });

      // canvas.backgroundColor = 'white';
      canvasRef.current.renderAll();
    }
  }, [width, height]);

  useEffect(() => {
    if (canvasRef.current) {
      const handleObjectAddedOrModified = () => {
        canvasRef.current.requestRenderAll();
        // Only save to history if it's not part of dragging
        if (!isDragging.current) {
          saveHistory(canvasRef.current);
        }
      };

      const handleObjectMoving = () => {
        canvasRef.current.requestRenderAll();
        // Mark as dragging to avoid redundant saves
        isDragging.current = true;
      };

      const handleMouseUp = () => {
        canvasRef.current.requestRenderAll();
        // Save the final position of the object after dragging
        if (isDragging.current) {
          isDragging.current = false; // Reset dragging state
          saveHistory(canvasRef.current); // Save the final state
        }
      };

      canvasRef.current?.on("object:added", handleObjectAddedOrModified);
      canvasRef.current?.on("object:modified", handleObjectAddedOrModified);
      canvasRef.current?.on("object:moving", handleObjectMoving);
      canvasRef.current?.on("mouse:up", handleMouseUp);

      return () => {
        canvasRef.current?.off("object:added", handleObjectAddedOrModified);
        canvasRef.current?.off("object:modified", handleObjectAddedOrModified);
        canvasRef.current?.off("object:moving", handleObjectMoving);
        canvasRef.current?.off("mouse:up", handleMouseUp);
      };
    }
  }, [canvasRef.current]);

  const elementFunc = (e, layers) => {
    for (let ele of layers) {
      if (ele.id == e.target.id) {
        console.log(ele);
        if (e.target.name == "Textbox") {
          if (ele.eleType == "text") {
            setTextBoxProps((prev) => ({
              ...prev,
              left: ele?.left,
              top: ele?.top,
              width: ele?.width,
              height: ele?.height,
              fill: ele.fill,
              stroke: ele.stroke,
              textAlign: ele.textAlign,
              shadow: ele.shadow,
              fontSize: ele.fontSize,
            }));
            setId(e.target?.id);
            setType("Text");
          }
          if (ele.eleType == "scrollstrip") {
            setTextStripProps((prev) => ({
              ...prev,
              left: ele?.left,
              top: ele?.top,
              width: ele?.width,
              height: ele?.height,
              text: ele.text,
              scrollstrip: ele?.scrollstrip ?? "",
              fill: ele.fill,
              stroke: ele.stroke,
              textAlign: ele.textAlign,
              shadow: ele.shadow,
              fontSize: ele.fontSize,
              isSelected: true,
            }));
            setId(e.target?.id);
            setType("TextStrip");
          }
        }
        // if (e.target.name == "Shape" || e.target?.type == "rect") {
        //   // console.log(e);
        //   if (e.target.shapeType == "rect") {
        //     setShapeProps((prev) => ({
        //       ...prev,
        //       shapeType: e.target.shapeType,
        //       fill: ele.fill,
        //       height: ele.height,
        //       width: ele.width,
        //       stroke: ele.stroke,
        //       shadow: ele.shadow,
        //       radius: ele.rx ?? 0,
        //       strokeWidth: ele.strokeWidth,
        //     }));
        //   }
        //   if (
        //     e.target.shapeType == "circle" ||
        //     e.target.shapeType == "line" ||
        //     e.target.shapeType == "polygone" ||
        //     e.target.shapeType == "triangle" ||
        //     ["circle", "line", "polygone", "triangle"].includes(
        //       e.target?.type
        //     )
        //   ) {
        //     setShapeProps((prev) => ({
        //       ...prev,
        //       offsetX:ele?.left,
        //       offsetY:ele?.top,
        //       width:ele?.width * ele?.scaleX,
        //       height:ele?.height * ele?.scaleY,
        //       shapeType: e.target.shapeType,
        //       fill: ele.fill,
        //       height: ele.height,
        //       width: ele.width,
        //       stroke: ele.stroke,
        //       shadow: ele.shadow,
        //       strokeWidth: ele.strokeWidth,
        //     }));
        //   }
        //   setId(e.target?.id);
        //   setType("Shape");
        //   setObjectKey("Shapes");
        // }
        if (e.target.type == "image") {
          if (ele.eleType == "qr-code") {
            setQrProps((prev) => ({
              ...prev,
              scaleX: ele?.scaleX,
              scaleY: ele?.scaleY,
              left: ele?.left,
              top: ele?.top,
              width: ele?.width,
              height: ele?.height,
            }));
            setId(e.target?.id);
            setType("QRCode");
          }
          if (ele.eleType == "weather") {
            setWeatherProps({
              ...ele.weatherObj,
              scaleX: ele?.scaleX,
              scaleY: ele?.scaleY,
              left: ele?.left,
              top: ele?.top,
              width: ele?.width,
              height: ele?.height,
              isSelected: true,
            });
            setId(e.target?.id);
            setType("Weather");
          }
          if (ele.eleType == "temp") {
            setTempProps({
              ...ele.tempObj,
              scaleX: ele?.scaleX,
              scaleY: ele?.scaleY,
              left: ele?.left,
              top: ele?.top,
              width: ele?.width,
              height: ele?.height,
              isSelected: true,
            });
            setId(e.target?.id);
            setType("Temprature");
          }
          if (["playlist", "nor-video", "nor-image"].includes(ele.eleType)) {
            setId(e.target.id);
            setType("ToolPlaylistOp");
            setPlaylistProps((prev) => ({
              ...prev,
              scaleX: ele?.scaleX,
              scaleY: ele?.scaleY,
              left: ele?.left,
              top: ele?.top,
              width: ele?.width,
              height: ele?.height,
              name: ele._name ?? "",
              type: ele.eleType,
              ...ele.scaletype,
            }));
          }
          if (ele.eleType == "analog-clock") {
            setTextTimeProps((prev) => ({
              ...prev,
              scaleX: ele?.scaleX,
              scaleY: ele?.scaleY,
              left: ele?.left,
              top: ele?.top,
              width: ele?.width,
              height: ele?.height,
              fill: ele.fill,
              stroke: ele.stroke,
              textAlign: ele.textAlign,
              shadow: ele.shadow,
              fontSize: ele.fontSize,
              isSelected: true,
            }));
            setId(e.target?.id);
            setType("Time");
          }
        }

        if (e.target.name == "Text" || e.target?.type == "text") {
          // console.log(ele);
          if (ele.eleType == "time") {
            setTextTimeProps((prev) => ({
              ...prev,
              scaleX: ele?.scaleX,
              scaleY: ele?.scaleY,
              left: ele?.left,
              top: ele?.top,
              width: ele?.width,
              height: ele?.height,
              fill: ele.fill,
              stroke: ele.stroke,
              textAlign: ele.textAlign,
              shadow: ele.shadow,
              fontSize: ele.fontSize,
              isSelected: true,
            }));
            setId(e.target?.id);
            setType("Time");
          }

          if (ele.eleType == "date") {
            setTextDateProps((prev) => ({
              ...prev,
              scaleX: ele?.scaleX,
              scaleY: ele?.scaleY,
              left: ele?.left,
              top: ele?.top,
              width: ele?.width,
              height: ele?.height,
              fill: ele.fill,
              stroke: ele.stroke,
              textAlign: ele.textAlign,
              shadow: ele.shadow,
              fontSize: ele.fontSize,
              isSelected: true,
            }));
            setId(e.target?.id);
            setType("Date");
          }

          // setObjectKey("TextProperty");
        }
        break;
      }
    }
  };

  useEffect(() => {
    canvasRef.current.on("object:moving", (e) => {
      elementFunc(e, layers);
    });

    canvasRef.current.on("object:scaling", (e) => {
      elementFunc(e, layers);
    });
  }, [layers]);

  useEffect(() => {
    canvasRef.current.on("mouse:down", (e) => {
      if (e.e.button == 2) {
        // console.log(e)
        setActiveObj(e.target);
        setMenuPosition({ top: e.e.clientY, left: e.e.clientX });
        setShowMenu(true);
        return;
      }
      if (e.target == null) {
        setShowMenu(false);
        // console.log(canvasRef.current);
        setCanvaProps((prev) => ({
          ...prev,
          name: canvasRef.current.name,
          height: canvasRef.current.height,
          width: canvasRef.current.width,
          backgroundColor: canvasRef.current.backgroundColor,
        }));
        setWeatherProps({ isSelected: false });
        setTempProps({ isSelected: false });
        setTextTimeProps((prev) => ({ ...prev, isSelected: false }));
        setTextDateProps((prev) => ({ ...prev, isSelected: false }));
        setTextStripProps((prev) => ({ ...prev, isSelected: false }));
        setObjectKey("default");
        setType("Design");
        return;
      }
      elementFunc(e, layers);
      // for (let ele of layers) {
      //   if (ele.id == e.target.id) {
      //     console.log(ele);
      //     if (e.target.name == "Textbox") {
      //       if (ele.eleType == "text") {
      //         setTextBoxProps((prev) => ({
      //           ...prev,
      //           fill: ele.fill,
      //           stroke: ele.stroke,
      //           textAlign: ele.textAlign,
      //           shadow: ele.shadow,
      //           fontSize: ele.fontSize,
      //         }));
      //         setId(e.target?.id);
      //         setType("Text");
      //       }
      //       if (ele.eleType == "scrollstrip") {
      //         setTextStripProps((prev) => ({
      //           ...prev,
      //           text: ele.text,
      //           scrollstrip: ele?.scrollstrip ?? "",
      //           fill: ele.fill,
      //           stroke: ele.stroke,
      //           textAlign: ele.textAlign,
      //           shadow: ele.shadow,
      //           fontSize: ele.fontSize,
      //           isSelected: true,
      //         }));
      //         setId(e.target?.id);
      //         setType("TextStrip");
      //       }
      //     }
      //     if (e.target.name == "Shape" || e.target?.type == "rect") {
      //       // console.log(e);
      //       if (e.target.shapeType == "rect") {
      //         setShapeProps((prev) => ({
      //           ...prev,
      //           shapeType: e.target.shapeType,
      //           fill: ele.fill,
      //           height: ele.height,
      //           width: ele.width,
      //           stroke: ele.stroke,
      //           shadow: ele.shadow,
      //           radius: ele.rx ?? 0,
      //           strokeWidth: ele.strokeWidth,
      //         }));
      //       }
      //       if (
      //         e.target.shapeType == "circle" ||
      //         e.target.shapeType == "line" ||
      //         e.target.shapeType == "polygone" ||
      //         e.target.shapeType == "triangle" ||
      //         ["circle", "line", "polygone", "triangle"].includes(
      //           e.target?.type
      //         )
      //       ) {
      //         setShapeProps((prev) => ({
      //           ...prev,
      //           shapeType: e.target.shapeType,
      //           fill: ele.fill,
      //           height: ele.height,
      //           width: ele.width,
      //           stroke: ele.stroke,
      //           shadow: ele.shadow,
      //           strokeWidth: ele.strokeWidth,
      //         }));
      //       }
      //       setId(e.target?.id);
      //       setType("Shape");
      //       setObjectKey("Shapes");
      //     }
      //     if(e.target.type=='image'){
      //       if(ele.eleType=='weather'){
      //         setWeatherProps({
      //           ...ele.weatherObj,
      //           isSelected: true,
      //         });
      //         setId(e.target?.id);
      //         setType("Weather");
      //       }
      //       if(ele.eleType == "temp"){
      //         setTempProps({
      //           ...ele.tempObj,
      //           isSelected: true,
      //         });
      //         setId(e.target?.id);
      //         setType("Temprature");
      //       }
      //       if(['playlist','nor-video','nor-image'].includes(ele.eleType)){
      //         setId(e.target.id);
      //         setType('ToolPlaylistOp');
      //         setPlaylistProps((prev)=>({
      //           ...prev,
      //           name:ele._name ?? '',
      //           type:ele.eleType,
      //           ...ele.scaletype
      //         }))
      //       }

      //     }

      //     if (e.target.name == "Text" || e.target?.type == "text") {
      //       // console.log(ele);
      //       if (ele.eleType == "time") {
      //         setTextTimeProps((prev) => ({
      //           ...prev,
      //           fill: ele.fill,
      //           stroke: ele.stroke,
      //           textAlign: ele.textAlign,
      //           shadow: ele.shadow,
      //           fontSize: ele.fontSize,
      //           isSelected: true,
      //         }));
      //         setId(e.target?.id);
      //         setType("Time");
      //       }

      //       if (ele.eleType == "date") {
      //         setTextDateProps((prev) => ({
      //           ...prev,
      //           offsetX:ele.left,
      //           offsetY:ele.top,
      //           width:ele.width * ele.scaleX,
      //           height:ele.height * ele.scaleY,
      //           fill: ele.fill,
      //           stroke: ele.stroke,
      //           textAlign: ele.textAlign,
      //           shadow: ele.shadow,
      //           fontSize: ele.fontSize,
      //           isSelected: true,
      //         }));
      //         setId(e.target?.id);
      //         setType("Date");
      //       }

      //       // setObjectKey("TextProperty");
      //     }
      //     break;
      //   }
      // }
      setShowMenu(false);
    });
  }, [layers]);
  useEffect(() => {
    if (isContentAdded) {
      // setFilteredContent([..._content]);
      toast.success("Template stored successfully.");
      onClearResources();
      setType("Design");
      setObjectKey("default");
      dispatch(contentFlagOff());
    }
    if (isContentUpdated) {
      toast.success("Template updated successfully.");
      // onClearResources();
      setType("Design");
      setObjectKey("default");
      dispatch(contentFlagOff());
    }
  }, [isContentAdded, isContentUpdated]);

  //   useEffect(()=>{
  //  const marqueeText = new fabric.Text('This is a scrolling marquee text!', {
  //         left: canvasRef.current.getWidth(), // Start from the right edge
  //         top: 30, // Position vertically in the middle
  //         fontSize: 30,
  //         fill: '#333',
  //         fontFamily: 'Arial'
  //     });

  //     canvasRef.current.add(marqueeText);

  //     // Animation function to move the text from right to left
  //     function animateMarquee() {
  //         marqueeText.animate('left', -marqueeText.width, {
  //             onChange: canvasRef.current.renderAll.bind(canvasRef.current),
  //             duration: 10000,
  //             easing: fabric.util.ease.linear,
  //             onComplete: function() {

  //                 marqueeText.set({ left: canvasRef.current.getWidth() });
  //                 animateMarquee();
  //             }
  //         });
  //     }
  // animateMarquee();
  //   },[])
  const saveHistory = (currentCanvas) => {
    const state = JSON.stringify(currentCanvas.toJSON());
    setHistory((prev) => [...prev, state]);
  };

  // Undo functionality
  const undo = () => {
    console.log("undo");
    if (history.length > 1) {
      const newHistory = [...history];
      const currentState = newHistory.pop(); // Remove the current state
      setRedoStack((prev) => [...prev, currentState]); // Save the current state to the redo stack
      setHistory(newHistory);

      const previousState = JSON.parse(newHistory[newHistory.length - 1]);
      canvasRef.current.loadFromJSON(previousState, () =>
        canvasRef.current.requestRenderAll()
      );
    }
  };

  // Redo functionality
  const redo = () => {
    console.log("redo");
    if (redoStack.length > 0) {
      const redoState = redoStack.pop(); // Get the last redo state
      setRedoStack(redoStack); // Update the redo stack
      setHistory((prev) => [...prev, redoState]); // Add redo state to history

      const state = JSON.parse(redoState);
      canvasRef.current.loadFromJSON(state, () =>
        canvasRef.current.requestRenderAll()
      );
    }
  };

  const panFunction = () => {
    if (canvasRef.current) {
      canvasRef.current.on("mouse:up", (e) => {
        // console.log(e.e);
        const delta = new fabric.Point(e.e.movementX, e.e.movementY);
        canvasRef.current.relativePan(delta);
        canvasRef.current.renderAll();
      });
    }
  };
  const DesignCatagory = (name) => {
    switch (name) {
      case "ToolPlaylistOp":
        return (
          <ToolPlaylistOp
            playlist={{
              playlistProps,
              onChangeParams: (obj) => {
                if (canvasRef.current) {
                  console.log(obj);
                  let _elementEl = layers.find((l) => l.id == id);
                  _elementEl.set({
                    left: obj.left,
                    top: obj.top,
                    // width: obj.width,
                    // height: obj.height,
                    scaleX: obj.scaleX,
                    scaleY: obj.scaleY,
                  });
                  setPlaylistProps((prev) => ({ ...prev, ...obj }));
                  canvasRef.current.requestRenderAll();
                }
              },
              onTypeChange: (e, callback) => {
                // console.log(e);
                if (canvasRef.current) {
                  let _playlistEl = layers.find((l) => l.id == id);
                  // console.log(_weatherEl);
                  _playlistEl?.set({
                    scaletype: {
                      ...e,
                    },
                  });
                  callback(e);
                  canvasRef.current.requestRenderAll();
                }
              },
              onSetAllProperties: (p) =>
                setPlaylistProps((prev) => ({ ...prev, ...p })),
            }}
          />
        );
      case "Design":
        return (
          <Design
            onGetImage={(f) => getImageFromLocalComputer(f)}
            layer={layers}
            _folder={_folderId}
            onLoadTemplateFromDb={onLoadTemplateFromDb}
          />
        );
        break;
      case "Text":
        return (
          <Text
            onSelectText={onSelectText}
            onAlignLeft={onAlignLeft}
            onAlignRight={onAlignRight}
            textProps={{
              onChangeParams: (obj) => {
                if (canvasRef.current) {
                  console.log(obj);
                  let _elementEl = layers.find((l) => l.id == id);
                  _elementEl.set({
                    left: obj.left,
                    top: obj.top,
                    width: obj.width,
                    height: obj.height,
                  });
                  setTextBoxProps((prev) => ({ ...prev, ...obj }));
                  _elementEl.setCoords();
                  canvasRef.current.requestRenderAll();
                }
              },
              onChangeTextFontColor: onChangeColor,
              onChangeTextFontSize: onChangeSize,
              onChangeTextFontTransparency: onChangeTextFontTransparency,
              onChangeTextBoxShadowBlur: onChangeShadowBlur,
              onChangeTextBoxShadowColor: onChangeShadowColor,
              onGetOffsetVal: onChangeShadowOffSets,
              onChangeTextBoxShadowState: onChangeShadowState,
              onSetAllProperties: (p) =>
                setTextBoxProps((prev) => ({ ...prev, ...p })),
              textBoxProps: textBoxProps,
            }}
          />
        );
        break;
      case "Picture":
        return (
          <Picture
            onLoadImageFromDb={onLoadImageFromDb}
            onGetImage={(f) => getImageFromLocalComputer(f)}
          />
        );
        break;
      case "Video":
        return (
          <Video
            onGetVideo={(v) => getVideoFromLocalComputer(v)}
            onLoadVideoFromDb={onLoadVideoFromDb}
          />
        );
        break;
      case "Playlist":
        return (
          <PlaylistCanva
            // onGetVideo={(v) => getVideoFromLocalComputer(v)}
            onLoadPlaylistFromDb={onLoadPlaylistFromDb}
          />
        );
        break;
      // case "Shape":
      //   return (
      //     <Shape
      //       onDrawShapes={onDrawShapes}
      //       onAddEmojiToCanva={onAddEmojiToCanva}
      //     />
      //   );
      //   break;
      case "Time":
        return (
          <Time
            onAddTime={onAddTime}
            onAddAnalogClock={onAddAnalogClock}
            handleTimeFormatChange={onChangeTimeFormate}
            handleTimeZoneChange={onChangeTimeZone}
            _textElement={{
              onChangeParams: (obj) => {
                if (canvasRef.current) {
                  console.log(obj);
                  let _elementEl = layers.find((l) => l.id == id);
                  _elementEl.set({
                    left: obj.left,
                    top: obj.top,
                    scaleX: obj.scaleX,
                    scaleY: obj.scaleY,
                  });
                  setTextTimeProps((prev) => ({ ...prev, ...obj }));
                  _elementEl.setCoords();
                  canvasRef.current.requestRenderAll();
                }
              },
              textBoxProps: textTimeProps,
              onChangeTextFontColor: onChangeColor,
              onChangeTextFontSize: onChangeSize,
              // onChangeTextFontTransparency: onChangeTextElFontTransparency,
              onChangeTextBoxShadowBlur: onChangeShadowBlur,
              onChangeTextBoxShadowColor: onChangeShadowColor,
              onChangeTimeShadowState: onChangeShadowState,
              onSetAllProperties: (p) =>
                setTextTimeProps((prev) => ({ ...prev, ...p })),
              onGetOffsetVal: onChangeShadowOffSets,
            }}
          />
        );
        break;
      case "Date":
        return (
          <DateWidget
            onAddDate={onAddDate}
            handleDateFormatChange={onChangeDateFormat}
            _textElement={{
              onChangeParams: (obj) => {
                if (canvasRef.current) {
                  console.log(obj);
                  let _elementEl = layers.find((l) => l.id == id);
                  _elementEl.set({
                    left: obj.left,
                    top: obj.top,
                    scaleX: obj.scaleX,
                    scaleY: obj.scaleY,
                  });
                  setTextDateProps((prev) => ({ ...prev, ...obj }));
                  _elementEl.setCoords();
                  canvasRef.current.requestRenderAll();
                }
              },
              textBoxProps: textDateProps,
              onChangeTextFontColor: onChangeColor,
              onChangeTextFontSize: onChangeSize,
              // onChangeTextFontTransparency: onChangeTextElFontTransparency,
              onChangeTextBoxShadowBlur: onChangeShadowBlur,
              onChangeTextBoxShadowColor: onChangeShadowColor,
              onChangeDateShadowState: onChangeShadowState,
              onSetAllProperties: (p) =>
                setTextDateProps((prev) => ({ ...prev, ...p })),
              onGetOffsetVal: onChangeShadowOffSets,
            }}
          />
        );
        break;
      case "QRCode":
        return (
          <QRCode
            onAddQr={onAddQr}
            qrProps={{
              onChangeParams: (obj) => {
                if (canvasRef.current) {
                  console.log(obj);
                  let _elementEl = layers.find((l) => l.id == id);
                  _elementEl.set({
                    left: obj.left,
                    top: obj.top,
                    // width: obj.width,
                    // height: obj.height,
                    scaleX: obj.scaleX,
                    scaleY: obj.scaleY,
                  });
                  setQrProps((prev) => ({ ...prev, ...obj }));
                  canvasRef.current.requestRenderAll();
                }
              },
              qrProps,
            }}
          />
        );
      case "Rss":
        return <RssComponent onLoadRssFromDb={onLoadRssFromDb} />;
      case "Weather":
        return (
          <Weather
            onAddWeather={onAddWeather}
            weatherElement={{
              weatherProps: weatherProps,
              onChangeParams: (obj) => {
                if (canvasRef.current) {
                  console.log(obj);
                  let _elementEl = layers.find((l) => l.id == id);
                  _elementEl.set({
                    left: obj.left,
                    top: obj.top,
                    // width: obj.width,
                    // height: obj.height,
                    scaleX: obj.scaleX,
                    scaleY: obj.scaleY,
                  });
                  setWeatherProps((prev) => ({ ...prev, ...obj }));
                  canvasRef.current.requestRenderAll();
                }
              },
              onChangeTextFontColor: (_color, cb) => {
                if (canvasRef.current) {
                  let _weatherEl = layers.find((l) => l.id == id);
                  // console.log(_weatherEl);
                  _weatherEl?.set({
                    weatherObj: {
                      ...weatherProps,
                      forecolor: `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`,
                    },
                  });
                  cb({
                    forecolor: `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`,
                  });
                  canvasRef.current.requestRenderAll();
                }
              },
              onChangeBackgroundColor: (_color, cb) => {
                if (canvasRef.current) {
                  let _weatherEl = layers.find((l) => l.id == id);
                  _weatherEl?.set({
                    weatherObj: {
                      ...weatherProps,
                      background: `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`,
                    },
                  });
                  cb({
                    background: `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`,
                  });
                  canvasRef.current.requestRenderAll();
                }
              },
              onSetAllProperties: (p) =>
                setWeatherProps((prev) => ({ ...prev, ...p })),
            }}
          />
        );
      case "Temprature":
        return (
          <Temperature
            onAddTemprature={onAddTemprature}
            tempElement={{
              tempProps: tempProps,
              onChangeParams: (obj) => {
                if (canvasRef.current) {
                  console.log(obj);
                  let _elementEl = layers.find((l) => l.id == id);
                  _elementEl.set({
                    left: obj.left,
                    top: obj.top,
                    // width: obj.width,
                    // height: obj.height,
                    scaleX: obj.scaleX,
                    scaleY: obj.scaleY,
                  });
                  setTempProps((prev) => ({ ...prev, ...obj }));
                  canvasRef.current.requestRenderAll();
                }
              },
              onChangeTextFontColor: (_color, cb) => {
                if (canvasRef.current) {
                  let _weatherEl = layers.find((l) => l.id == id);
                  // console.log(_weatherEl);
                  _weatherEl?.set({
                    tempObj: {
                      ...tempProps,
                      forecolor: `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`,
                    },
                  });
                  cb({
                    forecolor: `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`,
                  });
                  canvasRef.current.requestRenderAll();
                }
              },
              onChangeBackgroundColor: (_color, cb) => {
                if (canvasRef.current) {
                  let _weatherEl = layers.find((l) => l.id == id);
                  _weatherEl?.set({
                    tempObj: {
                      ...tempProps,
                      background: `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`,
                    },
                  });
                  cb({
                    background: `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`,
                  });
                  canvasRef.current.requestRenderAll();
                }
              },
              onSetAllProperties: (p) =>
                setTempProps((prev) => ({ ...prev, ...p })),
            }}
          />
        );
      case "TextStrip":
        return (
          <TextStrip
            _textStripElement={{
              onChangeParams: (obj) => {
                if (canvasRef.current) {
                  console.log(obj);
                  let _elementEl = layers.find((l) => l.id == id);
                  _elementEl.set({
                    left: obj.left,
                    top: obj.top,
                    scaleX: obj.scaleX,
                    scaleY: obj.scaleY,
                  });
                  setTextStripProps((prev) => ({ ...prev, ...obj }));
                  _elementEl.setCoords();
                  canvasRef.current.requestRenderAll();
                }
              },
              textBoxProps: textStripProps,
              scrollVal: scrollStrip,
              onChangeTextFontColor: onChangeColor,
              onChangeTextFontSize: onChangeSize,
              onGetValue: (e) => {
                setScrollStrip((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }));
                // setTextStripProps((prev)=>({...prev,speedValue:e}))
              },

              onAddTextStrip: onAddTextStrip,
              // onStartAnimation:onStartAnimation,
              // onChangeTextFontTransparency: onChangeTextElFontTransparency,
              onChangeTextBoxShadowBlur: onChangeShadowBlur,
              onChangeTextBoxShadowColor: onChangeShadowColor,
              onChangeTimeShadowState: onChangeShadowState,
              onSetAllProperties: (p) =>
                setTextStripProps((prev) => ({ ...prev, ...p })),
              onGetOffsetVal: onChangeShadowOffSets,
            }}
          />
        );
      default:
        return "Invalid";
    }
  };
  const onDrawShapes = (name) => {
    let shape;
    if (canvasRef.current) {
      if (name === "Rect") shape = new EditorOps().createRect();
      if (name === "Circle") shape = new EditorOps().createCircle();
      if (name === "Line") shape = new EditorOps().createLine();
      if (name === "Star") shape = new EditorOps().createPolygone();
      if (name === "Triangle") shape = new EditorOps().createTriangle();
      setShapeProps((prev) => ({
        ...prev,
        fill: "gray",
        stroke: "black",
        strokeWidth: 1,
        isShadow: false,
        shadow: {},
      }));
      setLayers((prev) => [...prev, shape]);
      canvasRef.current.add(shape);
      canvasRef.current.requestRenderAll();
    }
  };
  const onAddEmojiToCanva = (emoji) => {
    const _emoji = new EditorOps().addEmojiToCanvas(emoji);
    setLayers((prev) => [...prev, _emoji]);
    canvasRef.current.add(_emoji);

    canvasRef.current.requestRenderAll();
  };
  const onAddTime = (time) => {
    const _time = new EditorOps().addTextToCanvas(time, "time");
    setTextTimeProps((prev) => ({ ...prev, isSelected: false }));
    setLayers((prev) => [...prev, _time]);
    canvasRef.current.add(_time);
    // setInterval(() => {
    //   const now = new Date();
    //   const formattedTime = now.toLocaleTimeString(); // Format: HH:mm:ss
    //   _time.text = `${formattedTime}`;
    //   // canvas.renderAll();
    //   canvasRef.current.requestRenderAll();
    // }, 1000);
    canvasRef.current.requestRenderAll();
  };
  const onAddTextStrip = () => {
    try {
      if (canvasRef.current) {
        const text = new EditorOps().addTextStripBox(
          scrollStrip.text,
          50,
          scrollStrip
        );
        // console.log(text, scrollStrip);
        text.set({
          scrollstrip: scrollStrip,
        });
        setTextStripProps((prev) => ({ ...prev, isSelected: false }));
        setLayers((prev) => [...prev, text]);
        canvasRef.current.add(text);
        canvasRef.current.requestRenderAll();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onAddAnalogClock = () => {
    if (canvasRef.current) {
      new EditorOps().addAnalogClock(
        `${localUrl}/analog-clock.jpg`,
        "analog-clock",
        (img) => {
          setLayers((prev) => [...prev, img]);
          canvasRef.current.add(img);
          canvasRef.current.requestRenderAll();
        }
      );

      // fetch(analogclock)
      //   .then((response) => response.arrayBuffer())
      //   .then((buffer) => {
      //     const blob = new Blob([buffer], { type: "image/png" });
      //     const imgurl = URL.createObjectURL(blob);

      //     new EditorOps().addAnalogClock(imgurl, "analog-clock", (img) => {
      //       setTextTimeProps((prev) => ({ ...prev, isSelected: false }));
      //       setLayers((prev) => [...prev, img]);
      //       canvasRef.current.add(img);

      //       canvasRef.current.requestRenderAll();
      //     });
      //   });
    }
  };

  const onChangeTimeFormate = (formatString) => {
    if (canvasRef.current) {
      const _Time = layers.filter((l) => l.id == id);
      const format = JSON.parse(formatString);
      let time = new Date().toLocaleTimeString(undefined, {
        ...format,
        timeZone: "UTC",
        hourCycle: "h23",
        hour: "2-digit",
        minute: "2-digit",
      });
      _Time[0]?.set("text", time);

      canvasRef.current.requestRenderAll();
    }
  };
  const onChangeTimeZone = (zone) => {
    if (canvasRef.current) {
      const _Time = layers.filter((l) => l.id == id);
      let time = new Date().toLocaleTimeString("en-US", {
        timeZone: zone,
        hourCycle: "h23",
        hour: "2-digit",
        minute: "2-digit",
      });
      _Time[0]?.set("text", time);

      canvasRef.current.requestRenderAll();
    }
  };
  const onAddDate = (date) => {
    const _date = new EditorOps().addTextToCanvas(date, "date");
    // const shadow = new EditorOps().addShadow();
    // _date.set("shadow", shadow);
    setTextDateProps((prev) => ({ ...prev, isSelected: false }));
    setLayers((prev) => [...prev, _date]);
    canvasRef.current.add(_date);
    canvasRef.current.requestRenderAll();
  };

  const onChangeDateFormat = (formatString) => {
    if (canvasRef.current) {
      let currentDate;
      const splited = formatString.split(">");
      const format = JSON.parse(splited[0]);
      // console.log(splited)
      // console.log(format)
      const _date = layers.filter((l) => l.id == id);
      if (splited[1] == "4") {
        currentDate = new Date()
          .toLocaleDateString("en-GB", format)
          .replace("/", "-")
          .replace("/", "-");
      } else {
        currentDate = new Date().toLocaleDateString("en-GB", format);
      }
      _date[0]?.set("text", currentDate);
      canvasRef.current.requestRenderAll();
    }
  };

  // all qr functionality
  function onAddQr(value) {
    const tempCanvas = document.createElement("canvas");
    const qr = new QRious({
      element: tempCanvas,
      value: value,
      size: 150,
    });

    const qrCodeUrl = tempCanvas.toDataURL();
    const formData = new FormData();
    fetch(qrCodeUrl)
      .then((response) => response.arrayBuffer())
      .then((buffor) => {
        const blob = new Blob([buffor], { type: "image/png" });
        formData.append("qrcode", blob, `qr-code.png`);
        api
          .post("/content/uploadqr", formData)
          .then((res) => {
            // const _qrCodeUrl = URL.createObjectURL(blob);
            new EditorOps().addImage(res.data.path, "qr", "qr-code", (img) => {
              img.set({ text: value });
              setLayers((prev) => [...prev, img]);
              canvasRef.current.add(img);
              canvasRef.current.requestRenderAll();
            });
          })
          .catch((error) => console.log(error));
      });
    // console.log(blob)
  }
  // all qr functionality end

  function getVideoElement(url) {
    var videoE = document.createElement("video");
    videoE.width = 530;
    videoE.height = 298;
    videoE.loop = true;
    videoE.autoplay = true;
    videoE.muted = true;
    videoE.crossOrigin = "anonymous";
    var source = document.createElement("source");
    source.src = url;
    source.type = "video/mp4";
    videoE.appendChild(source);
    return videoE;
  }
  const onLoadVideoFromDb = (_obj) => {
    // videoPlayerThumbnail
    if (canvasRef.current) {
      new EditorOps().addLocalContent(
        `${localUrl}/video-icon.png`,
        _obj.url,
        _obj.name,
        "nor-video",
        (img) => {
          setLayers((prev) => [...prev, img]);
          canvasRef.current.add(img);
          canvasRef.current.requestRenderAll();
        }
      );
      // fetch(video_icon)
      // .then(res=>res.arrayBuffer())
      // .then(buffer=>{
      //   const blob = new Blob([buffer], { type: "image/png" });
      //   const imgurl = URL.createObjectURL(blob);
      // new EditorOps().addLocalContent( imgurl,_obj.url, _obj.name,"nor-video",(img)=>{
      //   setLayers((prev) => [...prev, img]);
      //   canvasRef.current.add(img);
      //   canvasRef.current.requestRenderAll();

      // })

      // })
    }
  };
  const onLoadImageFromDb = (url, name) => {
    if (canvasRef.current) {
      new EditorOps().addImage(url, name, "nor-image", (img) => {
        setLayers((prev) => [...prev, img]);
        canvasRef.current.add(img);
        canvasRef.current.requestRenderAll();
      });
    }
  };
  const onLoadRssFromDb = (url) => {
    if (canvasRef.current) {
      new EditorOps().addLocalContent(
        `${localUrl}/rss-icon.png`,
        url,
        "",
        "rss",
        (img) => {
          setLayers((prev) => [...prev, img]);
          canvasRef.current.add(img);
          canvasRef.current.requestRenderAll();
        }
      );
      // fetch(rss_icon)
      //   .then((response) => response.arrayBuffer())
      //   .then((buffer) => {
      //     const blob = new Blob([buffer], { type: "image/png" });

      //     const imgurl = URL.createObjectURL(blob);
      //     new EditorOps().addLocalContent(imgurl, url, "rss", (img) => {
      //       setLayers((prev) => [...prev, img]);
      //       canvasRef.current.add(img);
      //       canvasRef.current.requestRenderAll();
      //     });
      //   });
    }
  };
  const onAddWeather = (obj) => {
    if (canvasRef.current) {
      new EditorOps().addWeather(
        `${localUrl}/weather_icon.png`,
        obj,
        "weather",
        (img) => {
          setLayers((prev) => [...prev, img]);
          canvasRef.current.add(img);
          canvasRef.current.requestRenderAll();
        }
      );
      // fetch(weather_icon)
      //   .then((response) => response.arrayBuffer())
      //   .then((buffer) => {
      //     const blob = new Blob([buffer], { type: "image/png" });

      //     const imgurl = URL.createObjectURL(blob);
      //     new EditorOps().addWeather(imgurl, obj, "weather", (img) => {
      //       setLayers((prev) => [...prev, img]);
      //       canvasRef.current.add(img);
      //       canvasRef.current.requestRenderAll();
      //     });
      //   });
    }
  };

  const onAddTemprature = (obj) => {
    if (canvasRef.current) {
      new EditorOps().addTemp(
        `${localUrl}/temp_icon.png`,
        obj,
        "temp",
        (img) => {
          setLayers((prev) => [...prev, img]);
          canvasRef.current.add(img);
          canvasRef.current.requestRenderAll();
        }
      );
      // fetch(temp_icon)
      //   .then((response) => response.arrayBuffer())
      //   .then((buffer) => {
      //     const blob = new Blob([buffer], { type: "image/png" });

      //     const imgurl = URL.createObjectURL(blob);
      //     new EditorOps().addTemp(imgurl, obj, "temp", (img) => {
      //       setLayers((prev) => [...prev, img]);
      //       canvasRef.current.add(img);
      //       canvasRef.current.requestRenderAll();
      //     });
      //   });
    }
  };

  const onLoadPlaylistFromDb = (_content, name) => {
    if (canvasRef.current) {
      new EditorOps().addPlaylist(
        `${localUrl}/playlist-video.png`,
        name,
        _content,
        (img) => {
          setLayers((prev) => [...prev, img]);
          canvasRef.current.add(img);
          canvasRef.current.requestRenderAll();
        }
      );
      // fetch(playlist_icon)
      //   .then((response) => response.arrayBuffer())
      //   .then((buffer) => {
      //     const blob = new Blob([buffer], { type: "image/png" });
      //     const _imgsrc = URL.createObjectURL(blob);
      //     new EditorOps().addPlaylist(_imgsrc,name, _content, (img) => {
      //       setLayers((prev) => [...prev, img]);
      //       canvasRef.current.add(img);
      //       canvasRef.current.requestRenderAll();
      //     });
      //   });
    }
  };
  const getImageFromLocalComputer = (f) => {
    const reader = new FileReader();
    reader.readAsDataURL(f);
    reader.onload = function () {
      if (canvasRef.current) {
        new fabric.Image.fromURL(reader.result, (img) => {
          img.scale(0.3);
          img.set({
            paintFirst: "fill",
            cornerColor: "black",
            type: "nor-image",
          });

          // img.filters.push(
          //   new fabric.Image.filters.Contrast({ contrast: 0.5 })
          // );
          // img.filters.push(new fabric.Image.filters.Blur(0.5));
          // img.applyFilters();
          setLayers((prev) => [...prev, img]);

          canvasRef.current.add(img);
          // canvasRef.current.requestRenderAll();
        });
      }
    };
  };

  function getVideoElement(url) {
    var videoE = document.createElement("video");
    videoE.width = 530;
    videoE.height = 298;
    videoE.muted = true;
    videoE.crossOrigin = "anonymous";
    var source = document.createElement("source");
    source.src = url;
    source.type = "video/mp4";
    videoE.appendChild(source);
    return videoE;
  }

  const getVideoFromLocalComputer = (v) => {
    const reader = new FileReader();
    reader.readAsDataURL(v);
    reader.onload = function () {
      if (canvasRef.current) {
        const videoE = getVideoElement(reader.result);
        const vid = new fabric.Image(videoE, {
          left: 200,
          top: 300,
          angle: -15,
          originX: "center",
          originY: "center",
          cornerColor: "black",
          objectCaching: false,
        });
        setLayers((prev) => [...prev, vid]);
        canvasRef.current.add(vid);
        // videoEl.load();
        vid.getElement().play();
        canvasRef.current.requestRenderAll();
      }
    };
  };

  const onAlignLeft = (align) => {
    setTextAlign(align);
  };
  const onAlignRight = (align) => {
    setTextAlign(align);
  };
  const onSelectText = (_text, fontsize) => {
    if (canvasRef.current) {
      const text = new EditorOps().addTextbox(_text, fontsize);
      setTextBoxProps((prev) => ({ ...prev, shadow: {}, isShadow: false }));
      // text.on("selected", (e) => console.log(e));
      setLayers((prev) => [...prev, text]);
      canvasRef.current.add(text);
      canvasRef.current.requestRenderAll();
    }
  };
  const genNewElement = (url, index) => {
    let videoE = document.createElement("video");
    videoE.src = url;
    videoE.loop = true;
    videoE.autoplay = true;
    videoE.muted = true;
    videoE.crossOrigin = "anonymous";
    return videoE;
  };

  const onLoadTemplateFromDb = (json, id, name) => {
    if (canvasRef.current) {
      try {
        const _json = JSON.parse(json);
        // let videos=_json.objects.filter(j=>j.eleType=='nor-video')
        // let other={..._json,objects:_json.objects.filter(j=>j.eleType!=='nor-video')}
        // console.log(videos)
        // // videos[0].forEach((v,index)=>{
        // let ele=genNewElement(videos[0].url,0)
        // console.log(ele)
        // // canvasRef.current.setDimensions(_json.canvasWidth,_json.canvasHeight)
        // ele.addEventListener("loadeddata",function(){
        //   new fabric.Image.fromURL(videos[0].url,img=>{
        //   img.set({
        //     url:videos[0].url,
        //   //  height:videos[0].height,
        //   //  width:videos[0].width,
        //   //  scaleX:videos[0].scaleX,
        //   //  scaleY:videos[0].scaleY,
        //   //  top:videos[0].top,
        //   //  left:videos[0].left,
        //   //  right:videos[0].right,
        //   //  bottom:videos[0].bottom,
        //    ...videos[0]
        //   })
        //   console.log(img)
        //   setLayers((prev) => [...prev, img]);
        // canvasRef.current.add(img);
        // function render() {
        //   if(canvasRef.current){
        //     img.setElement(ele);
        //     img.getElement().play();
        //     canvasRef.current.requestRenderAll();
        //     requestAnimationFrame(render);
        //   }
        // }
        // render();
        //   },{crossOrigin:'anonymous'})

        // })

        // console.log(other);
        // console.log("Loaded JSON:", JSON.stringify(_json, null, 2));
        // console.log(_json);
        setIsEditMode(true);
        setCanvaId(id);
        setTemplateName(name);
        setHeight(_json.canvasHeight);
        setWidth(_json.canvasWidth);
        //   _json.objects = _json.objects.map((obj) => {
        //     if (obj.type === 'textbox' && !obj.text) {
        //         obj.text = ''; // Provide default text
        //     }
        //     return obj;
        // });

        canvasRef.current.loadFromJSON(
          _json,
          () => {
            setLayers(canvasRef.current.getObjects());
            canvasRef.current.requestRenderAll();
          }
          // (object, fabricObject) => {
          //   console.log("Object during load:", object);
          //   if (object.id !== undefined || object.id!=='') fabricObject.id = object.id;
          //   if (object.name !== undefined || object.name!=='') fabricObject.name = object.name;
          //   if (object.eleType !== undefined || object.eleType!=='')
          //     fabricObject.eleType = object.eleType;
          // }
        );
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  };
  const handleWidthChange = (e) => {
    // console.log('width->',e)
    const newWidth = parseInt(e, 10);
    if (!isNaN(newWidth) && newWidth > 0) {
      setWidth(newWidth);
      const newHeight = Math.round(newWidth / aspectRatio.current);
      setHeight(newHeight);
    }
  };
  const handleHeightChange = (e) => {
    // console.log('height->',e)
    const newHeight = parseInt(e, 10);
    if (!isNaN(newHeight) && newHeight > 0) {
      setHeight(newHeight);
      // if (maintainAspectRatio) {
      const newWidth = Math.round(newHeight * aspectRatio.current);
      setWidth(newWidth);
      // }
    }
  };

  const onSetHeightWidthOfCanva = (obj) => {
    if (canvasRef.current) {
      setHeight(obj.height);
      setWidth(obj.width);
      // handleWidthChange(obj.width);
      // handleHeightChange(obj.height);
      // const height = canvasRef.current.getHeight();
      // const width = canvasRef.current.getWidth();
      // const newHeight = height + (obj.height - height);
      // const newWidth = width + (obj.width - width);
      // setCanvaProps((prev) => ({
      //   ...prev,
      //   height: newHeight,
      //   width: newWidth,
      // }));
      // canvasRef.current.setHeight(newHeight);
      // canvasRef.current.setWidth(newWidth);
      // canvasRef.current.requestRenderAll();
    }
  };
  const onGetChangeCanvaBackgroundColor = (color) => {
    if (canvasRef.current) {
      setCanvaColor(color.hex);
      setCanvaProps((prev) => ({
        ...prev,
        backgroundColor: color,
      }));
      canvasRef.current.set("backgroundColor", color);
      canvasRef.current.requestRenderAll();
    }
  };

  // all textbox functions start
  const onChangeShadowState = (_state, cb) => {
    const _shape = layers.filter((l) => l.id == id);
    if (_state) {
      const shadow = new EditorOps().addShadow();
      _shape[0].set({ shadow: shadow });
      cb({
        shadow: { color: "black", blur: 5, offsetY: 10, offsetX: 10 },
        isShadow: true,
      });
      // setTextBoxProps((prev)=>({...prev,shadow:{color:'black',blur:5,offsetY:10,offsetX:10},isShadow:true}));
    } else {
      _shape[0].set({ shadow: {} });
      cb({ shadow: {}, isShadow: false });
      // setTextBoxProps((prev)=>({...prev,shadow:{},isShadow:false}));
    }
    canvasRef.current.requestRenderAll();
  };
  const onChangeColor = (_color, cb) => {
    try {
      if (canvasRef.current) {
        const _element = layers.filter((l) => l.id == id);
        _element[0]?.set(
          "fill",
          `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`
        );
        cb({ fill: `rgba(${_color.r},${_color.g},${_color.b},${_color.a})` });
        canvasRef.current.requestRenderAll();
      }
    } catch (error) {
      toast.error("Select element");
    }
  };

  const onChangeSize = (_size, cb) => {
    if (canvasRef.current) {
      const _element = layers.filter((l) => l.id == id);
      _element[0]?.set("fontSize", _size);
      cb({ fontSize: _size });
      // setTextBoxProps((prev) => ({ ...prev, fontSize: _size }));
      canvasRef.current.requestRenderAll();
    }
  };
  const onChangeShadowBlur = (value, cb) => {
    if (canvasRef.current) {
      const _element = layers.filter((l) => l.id == id);
      _element[0]?.set("shadow", {
        ..._element[0].shadow,
        color: _element[0]?.shadow?.color,
        blur: value,
      });

      cb({ shadow: { ..._element[0].shadow, blur: value } });
      canvasRef.current.requestRenderAll();
    }
  };
  const onChangeShadowColor = (_color, cb) => {
    if (canvasRef.current) {
      const _element = layers.filter((l) => l.id == id);
      _element[0]?.set("shadow", {
        ..._element[0].shadow,
        color: `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`,
        blur: _element[0]?.shadow?.blur,
      });
      cb({
        shadow: {
          ..._element[0].shadow,
          color: `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`,
        },
      });

      canvasRef.current.requestRenderAll();
    }
  };
  const onChangeShadowOffSets = (offsets, cb) => {
    if (canvasRef.current) {
      const _element = layers.filter((l) => l.id == id);
      _element[0]?.set("shadow", {
        ..._element[0].shadow,
        offsetX: offsets.x,
        offsetY: offsets.y,
      });
      cb({
        shadow: {
          // ...prev.shadow,
          ..._element[0].shadow,
          offsetX: offsets.x,
          offsetY: offsets.y,
        },
      });
      canvasRef.current.requestRenderAll();
    }
  };

  const onChangeTextFontTransparency = (_value) => {
    console.log(_value);
  };

  // all shapes functions

  const onChangeBorderColorCompleteOfShape = (_color) => {
    if (canvasRef.current) {
      console.log(_color);
      const _shape = layers.filter((s) => s.id == id);
      _shape[0].set(
        "stroke",
        `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`
      );
      setShapeProps((prev) => ({
        ...prev,
        stroke: `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`,
      }));
      canvasRef.current.requestRenderAll();
    }
  };
  const onChangeBorderThicknessOfShape = (_value) => {
    // console.log(_value)
    if (canvasRef.current) {
      const _shape = layers.filter((s) => s.id == id);
      _shape[0].set({ strokeWidth: _value, strokeUnform: true });
      // _shape[0].set('strokeUniform', true);
      setShapeProps((prev) => ({
        ...prev,
        strokeWidth: _value,
      }));
      canvasRef.current.requestRenderAll();
    }
  };
  const onChangeBorderRoundedOfShape = (_value) => {
    if (canvasRef.current) {
      const _shape = layers.filter((s) => s.id == id);
      _shape[0].set("rx", _value);
      _shape[0].set("ry", _value);
      setShapeProps((prev) => ({
        ...prev,
        radius: _value,
      }));
      canvasRef.current.requestRenderAll();
    }
  };

  // all shapes functions end

  const createVideoElement = (url) => {
    const video = document.createElement("video");
    video.src = url; // Replace with your video URL
    video.crossOrigin = "anonymous"; // Ensure cross-origin compatibility
    video.autoplay = true;
    video.loop = true;
    video.muted = true; // Optional, depends on your use case
    return video;
  };
  const addVideoToCanvas = (videoElement, canvas, setLayers) => {
    videoElement.addEventListener("loadeddata", () => {
      const videoImage = new fabric.Image(videoElement, {
        left: 100,
        top: 100,
        scaleX: 0.5,
        scaleY: 0.5,
        selectable: true,
      });

      // Add the video image to the canvas and update the layers
      canvas.current.add(videoImage);
      canvas.requestRenderAll();
      setLayers((prevLayers) => [...prevLayers, videoImage]);

      console.log("Video successfully added to the canvas.");
    });

    // Handle potential video loading errors
    videoElement.addEventListener("error", (e) => {
      console.error("Failed to load video:", e);
    });
  };
  const onClearResources = () => {
    let allCanvaObjs = canvasRef?.current?.getObjects();
    allCanvaObjs.forEach((ele) => {
      canvasRef?.current?.remove(ele);
    });
    setCanvaProps((prev) => ({
      ...prev,
      backgroundColor: "white",
    }));
    canvasRef.current.set("backgroundColor", "white");
    canvasRef.current.requestRenderAll();
    setLayers([]);
  };
  const onStartNew = () => {
    onClearResources();
    setHeight(600);
    setWidth(900);
    setTemplateName("");
    setIsEditMode(false);
  };
  const zoomIn = () => {
    if (canvasRef.current) {
      const newZoom = zoom + 0.1;
      setZoom(newZoom);
      canvasRef.current.setZoom(newZoom);
    }
  };

  // Zoom Out
  const zoomOut = () => {
    if (canvasRef.current) {
      const newZoom = Math.max(zoom - 0.1, 0.1); // Prevent zero or negative zoom
      setZoom(newZoom);
      canvasRef.current.setZoom(newZoom);
    }
  };

  return (
    <>
      <div className="editor-container">
        <header className="editor-header">
          <div className="header-left">
            <div
              style={{
                width: "70px",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={"/images/meliora-logo.png"}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <h3>Canvas Editor</h3>
          </div>
          <div className="header-right">
            <PropComponent
              canvaProps={{
                height: canvasLength.height,
                width: canvasLength.width,
                canvaColor,
                zoom,
                isEdit: isEditMode,
                canvaProps: canvaProps,
                templateName: templateName,
                onZoomIn: zoomIn,
                onZoomOut: zoomOut,
                onRedu: redo,
                onUndo: undo,
                onGetDimention: (e) => {
                  const { name, value } = e.target;
                  setCanvasLength((prev) => ({ ...prev, [name]: value }));
                  //  if(name=='height'){
                  //   setHeight(value);
                  //   handleHeightChange(value);
                  //  }
                  //  if(name=='width'){
                  //   setWidth(value)
                  //   handleWidthChange(value);
                  //  }
                },
                onChange: (_name) => setTemplateName(_name),
                onClear: () => {
                  onClearResources();
                },
                onExport: () => {
                  if (canvasRef.current) {
                    // const canvaObj = JSON.stringify(
                    //   canvasRef?.current?.toJSON()
                    // );
                    // console.log(canvasRef.current.toJSON());
                    const link = document.createElement("a");
                    link.download = "exported_from_meliora.png";
                    link.href = canvasRef?.current?.toDataURL();
                    link.click();
                  }
                },
                onSave: () => {
                  if (canvasRef.current) {
                    const canvasJSON = canvasRef.current.toJSON();
                    canvasJSON.canvasHeight = height;
                    canvasJSON.canvasWidth = width;
                    // Add canvas width and height to the JSON
                    canvasJSON.canvasWidth = canvasRef.current.getWidth();
                    canvasJSON.canvasHeight = canvasRef.current.getHeight();
                    canvasJSON.zoom = Math.round(zoom * 100);
                    const canvaObj = JSON.stringify(canvasJSON);
                    console.log(canvasJSON);
                    if (templateName == "") {
                      toast.error("Template name is required.");
                      return;
                    }
                    dispatch(
                      _addFromCanvaTemplate({
                        type: "template",
                        name: templateName,
                        user:
                          user.role == "user-admin"
                            ? user?.parentId?._id
                            : user?._id,
                        folderId: _folderId,
                        canvaObj,
                      })
                    );
                  }
                },
                onEdit: () => {
                  if (canvasRef.current) {
                    const canvasJSON = canvasRef.current.toJSON();
                    canvasJSON.canvasHeight = height;
                    canvasJSON.canvasWidth = width;
                    // Add canvas width and height to the JSON
                    canvasJSON.canvasWidth = canvasRef.current.getWidth();
                    canvasJSON.canvasHeight = canvasRef.current.getHeight();
                    canvasJSON.zoom = Math.round(zoom * 100);
                    const canvaObj = JSON.stringify(canvasJSON);
                    console.log(canvasJSON);
                    if (templateName == "") {
                      toast.error("Template name is required.");
                      return;
                    }
                    dispatch(
                      _editToCanvaTemplate(canvaId, {
                        type: "template",
                        name: templateName,
                        user:
                          user.role == "user-admin"
                            ? user?.parentId?._id
                            : user?._id,
                        folderId: _folderId,
                        canvaObj,
                      })
                    );
                  }
                },
                onStartNew,
                onGetChangeCanvaBackgroundColor: (color) =>
                  onGetChangeCanvaBackgroundColor(color),
              }}
              onSetLengthOfCanvas={(obj) =>
                onSetHeightWidthOfCanva(canvasLength)
              }
              // textProps={{
              //   onChangeTextFontColor: onChangeTextFontColor,
              //   onChangeTextFontSize: onChangeTextFontSize,
              //   onChangeTextFontTransparency: onChangeTextFontTransparency,
              //   onChangeTextBoxShadowBlur: onChangeTextBoxShadowBlur,
              //   onChangeTextBoxShadowColor: onChangeTextBoxShadowColor,
              //   onGetOffsetVal: onChangeTextBoxShadowOffSets,
              //   textBoxProps: textBoxProps,
              // }}
              shapeProps={{
                onChangeColorCompleteOfShape: onChangeColor,
                onChangeBorderColorCompleteOfShape:
                  onChangeBorderColorCompleteOfShape,
                onChangeBorderThicknessOfShape: onChangeBorderThicknessOfShape,
                onChangeBorderRoundedOfShape: onChangeBorderRoundedOfShape,
                onChangeShapeOffsetVal: onChangeShadowOffSets,
                onChangeShadowState: onChangeShadowState,
                onSetAllProperties: (p) =>
                  setShapeProps((prev) => ({ ...prev, ...p })),
                _shapeProps: shapeProps,
              }}
            />
          </div>
        </header>
        <div className="editor-main-body">
          <div className="editor-side-bar">
            <div className="tabs">
              {[
                { name: "Design", icon: <CDesign />, to: "Design" },
                { name: "Text", icon: <CText />, to: "Text" },
                {
                  name: "Picture",
                  icon: <CImage />,
                  to: "Picture",
                },
                {
                  name: "Video",
                  icon: <CVideo />,
                  to: "Video",
                },
                { name: "Playlist", icon: <CPlaylist />, to: "Playlist" },
                // {
                //   name: "Shape",
                //   icon: <RoundedCornerOutlinedIcon />,
                //   to: "Shape",
                // },
                { name: "Time", icon: <CTime />, to: "Time" },
                { name: "Date", icon: <CDate />, to: "Date" },
                { name: "QR Code", icon: <CQrCode />, to: "QRCode" },
                { name: "RSS", icon: <Rss />, to: "Rss" },
                { name: "Weather", icon: <CloudSun />, to: "Weather" },
                {
                  name: "Temprature",
                  icon: <ThermometerSun />,
                  to: "Temprature",
                },
                { name: "Text Strip", icon: <CText />, to: "TextStrip" },
              ].map((ele, index) => {
                return (
                  <div
                    key={index}
                    className="tab-menu"
                    onClick={() => setType(ele.to)}
                  >
                    {ele.icon}
                    <span>{ele.name}</span>
                  </div>
                );
              })}
              {/* <div className="tab-menu" onClick={() => setType("Text")}>
                <FormatColorTextIcon />
                <span>Text</span>
              </div>
              <div className="tab-menu" onClick={() => setType("Picture")}>
                <PanoramaOutlinedIcon />
                <span>Picture</span>
              </div>
              <div className="tab-menu" onClick={() => setType("Video")}>
                <OndemandVideoOutlinedIcon />
                <span>Video</span>
              </div>
              <div className="tab-menu" onClick={() => setType("Playlist")}>
                <ListPlus />
                <span>Playlist</span>
              </div>
              <div className="tab-menu" onClick={() => setType("Shape")}>
                <RoundedCornerOutlinedIcon />
                <span>Shape</span>
              </div>
              <div className="tab-menu" onClick={() => setType("Time")}>
                <Clock />
                <span>Time</span>
              </div>
              <div className="tab-menu" onClick={() => setType("Date")}>
                <CalendarDays />
                <span>Date</span>
              </div>
              <div className="tab-menu" onClick={() => setType("QRCode")}>
                <QrCode />
                <span>QR Code</span>
              </div> */}
            </div>
            <div className="tabs-panel">{DesignCatagory(type)}</div>
          </div>
          <div className="editor-body" id="outer-container">
            <video
              id={"video"}
              style={{ display: "none" }}
              height="260"
              width="380"
            ></video>
            <canvas ref={canvasRef} id={"canvas"}></canvas>
            {showMenu && (
              <div
                style={{
                  position: "absolute",
                  top: `${menuPosition.top}px`,
                  left: `${menuPosition.left}px`,
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  padding: "5px",
                  zIndex: 1000,
                  minHeight: "10dvh",
                  width: "20dvw",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "5px",
                }}
                onContextMenu={(e) => e.preventDefault()} // Prevent the default context menu
              >
                <div style={{ height: "30px", width: "100%" }}>
                  <button
                    onClick={() => {
                      if (canvasRef.current) {
                        // console.log(layers, activeObj);
                        setLayers(layers.filter((l) => l.id !== activeObj.id));
                        canvasRef.current.remove(activeObj);
                        canvasRef.current.requestRenderAll();
                        setActiveObj({});
                        setShowMenu(false);
                      }
                    }}
                    style={{
                      height: "100%",
                      width: "100%",
                      border: "1px solid gray",
                      borderRadius: "5px",
                    }}
                  >
                    Remove
                  </button>
                </div>

                <div style={{ height: "30px", width: "100%" }}>
                  <button
                    onClick={() => {
                      if (canvasRef.current) {
                        const act = canvasRef.current.getActiveObject();
                        if (act && typeof act.bringToFront == "function") {
                          // console.log("Active Object:", act);
                          canvasRef.current.bringToFront(act);
                          canvasRef.current.renderAll(); // Ensure canvas re-renders
                          setActiveObj({}); // Optional: Reset active object state
                          setShowMenu(false); // Optional: Close the menu
                        } else {
                          console.log("No object selected!");
                        }
                      } else {
                        console.log("Canvas is not initialized.");
                      }
                    }}
                    style={{
                      height: "100%",
                      width: "100%",
                      border: "1px solid gray",
                      borderRadius: "5px",
                    }}
                  >
                    Bring Front
                  </button>
                </div>

                <div style={{ height: "30px", width: "100%" }}>
                  <button
                    onClick={() => {
                      if (canvasRef.current) {
                        // const ele = layers.filter((l) => l.id == activeObj.id);
                        // ele[0]?.sendToBack();
                        // activeObj.sendToBack();
                        const acts = canvasRef.current.getActiveObject();
                        if (acts) {
                          // console.log(acts)
                          canvasRef.current.sendToBack(acts);
                          canvasRef.current.requestRenderAll();
                          setActiveObj({});
                          setShowMenu(false);
                        }
                      }
                    }}
                    style={{
                      height: "100%",
                      width: "100%",
                      border: "1px solid gray",
                      borderRadius: "5px",
                    }}
                  >
                    Send Back
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
