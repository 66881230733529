import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'

function DeleteModel({open,onClose,onConfirm}) {
  return (
   <>
     <Dialog sx={{
        // maxWidth: '20vw',
        // height: '30vh'
        zIndex:'100000000'
      }} open={open} onClose={() => onClose(false)}>
        <DialogTitle sx={{
          fontWeight: '700',
          fontSize: '18px',
          borderBottom: '2px solid var(--primary-color)',
          mb: 2,
          display: 'flex',
          justifyContent: 'center'
        }}>
          Delete
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{
              color: 'var(--primary-color)'
            }}>Are you sure you want to delete this screen?</Typography>
            <Box sx={{
              display: 'flex',
              mt: 5
            }}>
              <button style={{
                background: 'white',
                padding: '8px',
                width: '130px',
                color: 'var(--primary-color)',
                border: '1px solid var(--primary-color)',
                marginRight: '10px',
                borderRadius: '5px'
              }} onClick={() => onClose(false)} className="btn danger">
                No
              </button>
              <button style={{
                background: 'var(--primary-color)',
                padding: '8px',
                width: '130px',
                color: 'white',
                border: 'none',
                borderRadius: '5px'
              }} onClick={onConfirm} className="btn primary">
                Yes
              </button>

            </Box>
          </Box>
        </DialogContent>
      </Dialog>
   </>
  )
}

export default DeleteModel