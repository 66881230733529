import { InfoOutlined } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import {
  _ContentFolderDelete,
  _DeleteFolder,
} from "../../Redux/Actions/folderAction";
import { useDispatch } from "react-redux";

function AlertModel({
  open,
  _module,
  selectedRow,
  onClose,
  icon,
  content,
  title,
  id,
}) {
  const dispatch = useDispatch();
  return (
    <>
      <Dialog open={open} onClose={() => onClose()} maxWidth={"lg"}>
        <DialogTitle
        sx={{
          fontWeight: '700',
          fontSize: '18px',
          borderBottom: '2px solid var(--primary-color)',
          mb: 2,
          display: 'flex',
          justifyContent: 'center'
        }}
        ><InfoOutlined /> {title}</DialogTitle>
           
        <DialogContent
         sx={{
          display: "flex",
          gap: "2px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        >
          <Box
             sx={{
              display: "flex",
              gap: "2px",
              // flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{
              color: 'var(--primary-color)'
            }}>{content}</Typography>
          </Box>
          <Box
           sx={{
            display: 'flex',
            mt: 5
          }}
          >
                <button
                 style={{
                  background: 'white',
                  padding: '8px',
                  width: '130px',
                  color: 'var(--primary-color)',
                  border: '1px solid var(--primary-color)',
                  marginRight: '10px',
                  borderRadius: '5px'
                }}
                  onClick={() => onClose()}
                >
                  No
                </button>
            <button
              style={{
                background: 'var(--primary-color)',
                padding: '8px',
                width: '130px',
                color: 'white',
                border: 'none',
                borderRadius: '5px'
              }}
              onClick={() => {
                if (_module == "screen") {
                  dispatch(_DeleteFolder(id));
                }
                if (_module == "content") {
                  dispatch(_ContentFolderDelete(id, selectedRow));
                  //  console.log(selectedRow)
                }
                onClose();
              }}
            >
              Yes
            </button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AlertModel;
