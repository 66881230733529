import React, { useState, useEffect } from "react";
import "../Time/time.css";
import SearchField from "../../../components/editor/SearchField";
import { Popover } from "@mui/material";
import { SketchPicker } from "react-color";
function TextStrip({
  onAddTime,
  onAddAnalogClock,
  _textStripElement: textProps,
  handleTimeFormatChange,
  handleTimeZoneChange,
}) {
  const [colorAncherEl, setColorAncherEl] = useState(null);
  const [shadowColorAncherEl, setShadowColorAncherEl] = useState(null);
  const [alignAncherEl, setAlignAncherEl] = useState(null);
  const [fontSizeAncherEl, setFontSizeAncherEl] = useState(null);
  //   const [fun,setFun]=useState('');
  const [fontTransparencyAncherEl, setFontTransparencyAncherEl] =
    useState(null);
  const [canvaColor, setCanvaColor] = useState("black");
  const [shadowCanvaColor, setShadowCanvaColor] = useState("black");
  const [length, setLength] = useState({
    x: 10,
    y: 10,
  });
  const colorOpen = Boolean(colorAncherEl);
  const shadowColorOpen = Boolean(shadowColorAncherEl);
  const alignOpen = Boolean(alignAncherEl);
  const fontSizeOpen = Boolean(fontSizeAncherEl);
  const fontTransparencyOpen = Boolean(fontTransparencyAncherEl);
  const colorId = colorOpen ? "simple-popover" : undefined;
  const shadowColorId = shadowColorOpen ? "simple-popover" : undefined;
  const alignId = alignOpen ? "simple-popover" : undefined;
  const fontSizeId = fontSizeOpen ? "simple-popover" : undefined;
  const [returnedFunction, setReturnedFunction] = useState(null);
  const fontTransparencyId = fontTransparencyOpen
    ? "simple-popover"
    : undefined;

  useEffect(() => {
    if (textProps) {
      setLength({
        x: textProps?.textBoxProps?.shadow?.offsetX,
        y: textProps?.textBoxProps?.shadow?.offsetY,
      });
    }
  }, [textProps]);
  const handleColorClick = (event) => {
    setColorAncherEl(event.currentTarget);
  };
  const handleColorClose = () => {
    setColorAncherEl(null);
  };
  const handleShadowColorClick = (event) => {
    setShadowColorAncherEl(event.currentTarget);
  };
  const handleShadowColorClose = () => {
    setShadowColorAncherEl(null);
  };
  const handleAlignClick = (event) => {
    setAlignAncherEl(event.currentTarget);
  };
  const handleAlignClose = () => {
    setAlignAncherEl(null);
  };
  const handleFontSizeClick = (event) => {
    setFontSizeAncherEl(event.currentTarget);
  };
  const handleFontSizeClose = () => {
    setFontSizeAncherEl(null);
  };
  const handleFontTransparencyClick = (event) => {
    setFontTransparencyAncherEl(event.currentTarget);
  };
  const handleFontTransparencyClose = () => {
    setFontTransparencyAncherEl(null);
  };
  const onChangeColorComplete = (color) => {
    // dispatch(setTextFontColor(color.rgb));
    textProps.onChangeTextFontColor(color.rgb, (properties) =>
      textProps.onSetAllProperties(properties)
    );
  };
  const onChangeShadowColorComplete = (color) => {
    // dispatch(setTextFontColor(color.rgb));
    textProps.onChangeTextBoxShadowColor(color.rgb, (properties) =>
      textProps.onSetAllProperties(properties)
    );
  };
  const onChangeOffsetValues = (e) => {
    setLength((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const _onAddTime = () => {
    const currentTime = new Date().toLocaleTimeString();
    onAddTime(currentTime);
  };
  const _onChangeTextShadowState = (e) => {
    if (e.target.checked) {
      textProps.onChangeTimeShadowState(true, (properties) =>
        textProps.onSetAllProperties(properties)
      );
    } else {
      textProps.onChangeTimeShadowState(false, (properties) =>
        textProps.onSetAllProperties(properties)
      );
    }
  };

  const onChangeParams = (e) => {
    const { name, value } = e.target;
    if (name == "scaleY") {

      textProps.onChangeParams({
        left: textProps.textBoxProps.left,
        top: textProps.textBoxProps.top,
        scaleY: +textProps.textBoxProps.scaleY?.toFixed(1),
        scaleX: +textProps.textBoxProps.scaleX?.toFixed(1),
        [name]: +value,
      });
      return;
    } else if (name == "scaleX") {
      textProps.onChangeParams({
        left: textProps.textBoxProps.left,
        top: textProps.textBoxProps.top,
        scaleY: +textProps.textBoxProps.scaleY?.toFixed(1),
        scaleX: +textProps.textBoxProps.scaleX?.toFixed(1),
        [name]: +value,
      });
      return;
    }
    textProps.onChangeParams({
      left: textProps.textBoxProps.left,
      top: textProps.textBoxProps.top,
      scaleY: +textProps.textBoxProps.scaleY?.toFixed(1),
        scaleX: +textProps.textBoxProps.scaleX?.toFixed(1),
      [name]: +value,
    });
  };
  return (
    <>
      <div className="time-container">
        {/* <SearchField /> */}

        <div className="tool--wrapper">
          <label htmlFor="barcodeValue">Write Text:</label>
          <div className="text--tool">
            <input
              type="text"
              id="barcodeValue"
              name={"text"}
              vlaue={textProps?.textBoxProps?.scrollVal?.text}
              onChange={(e) => textProps.onGetValue(e)}
              style={{
                width: "100%",
                height: "100%",
                background: "white",
                border: "none",
                outline: "none",
              }}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <label htmlFor="barcodeType">Select Direction:</label>
          <div className="text--tool">
            <select
              name={"direction"}
              onChange={(e) => textProps.onGetValue(e)}
              style={{
                width: "100%",
                height: "100%",
                background: "white",
                border: "none",
                outline: "none",
              }}
            >
              <option value=""></option>
              <option value="right">Right</option>
              <option value="left">Left</option>
            </select>
          </div>
        </div>

        <div className="tool--wrapper">
          <label htmlFor="barcodeValue">Speed value in Millisecond:</label>
          <div className="text--tool">
            <input
              type="text"
              id="barcodeValue"
              name={"duration"}
              vlaue={textProps?.textBoxProps?.scrollVal?.duration}
              onChange={(e) => textProps.onGetValue(e)}
              style={{
                width: "100%",
                height: "100%",
                background: "white",
                border: "none",
                outline: "none",
              }}
            />
          </div>
        </div>
        {textProps?.textBoxProps?.isSelected && (
          <>
            <div className="tool--wrapper">
              <p>Text Color:</p>
              <div className="text--tool">
                <button className="buttons" onClick={handleColorClick}>
                  <div
                    style={{
                      height: "15px",
                      width: "15px",
                      border: "1px solid gray",
                      borderRadius: "50%",
                      background: `${textProps?.textBoxProps?.fill}`,
                    }}
                  ></div>
                  <span>{textProps?.textBoxProps?.fill}</span>
                </button>
                <Popover
                  id={colorId}
                  open={colorOpen}
                  anchorEl={colorAncherEl}
                  onClose={handleColorClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <SketchPicker
                    color={canvaColor}
                    onChangeComplete={onChangeColorComplete}
                  />
                </Popover>
              </div>
            </div>

            <div className="tool--wrapper">
              <p>Font Size:</p>
              <div className="text--tool">
                <input
                  type={"range"}
                  value={textProps?.textBoxProps?.fontSize}
                  min={0}
                  onChange={(e) =>
                    textProps.onChangeTextFontSize(
                      e.target.value,
                      (properties) => textProps.onSetAllProperties(properties)
                    )
                  }
                />
              </div>
            </div>
          </>
        )}

        <div className="tool--wrapper">
          <button
            style={{
              height: "40px",
              width: "100%",
              background: "var(--primary-color)",
              color: "var(--primary-forecolor)",
              border: "1px solid gray",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={textProps.onAddTextStrip}
          >
            {/* <Clock />  */}
            Add Text
          </button>
        </div>

        {/* <div className="tool--wrapper">
        <p>Shadow:</p>{" "}
      <div className="text--tool">
        <input
          type={"checkbox"}
          style={{ height: "20px", width: "20px" }}
          checked={textProps.textBoxProps.isShadow}
          onChange={_onChangeTextShadowState}
        />
      </div>
    </div> */}

<div className="tool--wrapper">
          <div className="text--tool">
            offsetX:
            <input
              type={"number"}
              value={textProps.textBoxProps?.left?.toFixed(2)}
              name={"left"}
              onChange={onChangeParams}
            />
            {/* offsetY:<input value={playlist?.playlistProps?.offsetY?.toFixed(2)}/> */}
          </div>
        </div>
        <div className="tool--wrapper">
          <div className="text--tool">
            {/* offsetX:<input value={playlist?.playlistProps?.offsetX?.toFixed(2)} /> */}
            offsetY:
            <input
              type={"number"}
              value={textProps.textBoxProps?.top?.toFixed(2)}
              name={"top"}
              onChange={onChangeParams}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            width:
            <input
              type={"number"}
              step={'0.1'}
              min={'0.1'}
              value={+textProps.textBoxProps?.scaleX?.toFixed(1)}
              name={"scaleX"}
              onChange={onChangeParams}
      // onKeyDown={handleKeyPress}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            height:
            <input
              type={"number"}
              step={'0.1'}
              min={'0.1'}
              value={+textProps.textBoxProps?.scaleY?.toFixed(1)}
              name={"scaleY"}
              onChange={onChangeParams}
      // onKeyDown={handleKeyPress}
              // style={{fontSize:'10px',width:'30px',height:'100%',border:'1px solid gray',borderRadius:'10px'}}
            />
          </div>
        </div>

        {textProps.textBoxProps.isShadow && (
          <>
            <div className="tool--wrapper">
              <p>Shadow:</p>
              <div className="text--tool">
                <input
                  type={"range"}
                  value={textProps?.textBoxProps?.shadow?.size}
                  min={0}
                  onChange={(e) =>
                    textProps.onChangeTextBoxShadowBlur(
                      e.target.value,
                      (properties) => textProps.onSetAllProperties(properties)
                    )
                  }
                />
              </div>
            </div>

            <div className="tool--wrapper">
              <p>shadow Color:</p>
              <div className="text--tool">
                <button className="buttons" onClick={handleShadowColorClick}>
                  <div
                    style={{
                      height: "15px",
                      width: "15px",
                      border: "1px solid gray",
                      borderRadius: "50%",
                      background: `${textProps?.textBoxProps?.shadow?.color}`,
                    }}
                  ></div>
                  <span>{textProps?.textBoxProps?.shadow?.color}</span>
                </button>
                <Popover
                  id={shadowColorId}
                  open={shadowColorOpen}
                  anchorEl={shadowColorAncherEl}
                  onClose={handleShadowColorClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <SketchPicker
                    color={shadowCanvaColor}
                    onChangeComplete={onChangeShadowColorComplete}
                  />
                </Popover>
              </div>
            </div>

            <div className="tool--wrapper">
              <p>offsetX:</p>
              <div className="text--tool">
                <input
                  type={"number"}
                  value={length.x}
                  name={"x"}
                  onChange={(e) => {
                    onChangeOffsetValues(e);
                    textProps.onGetOffsetVal(
                      { x: e.target.value, y: length.y },
                      (properties) => textProps.onSetAllProperties(properties)
                    );
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="tool--wrapper">
              <p>offsetY:</p>
              <div className="text--tool">
                <input
                  type={"number"}
                  value={length.y}
                  name={"y"}
                  onChange={(e) => {
                    onChangeOffsetValues(e);
                    textProps.onGetOffsetVal(
                      { y: e.target.value, x: length.x },
                      (properties) => textProps.onSetAllProperties(properties)
                    );
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default TextStrip;
