import React, { useState } from 'react'
import '../QRCode/qrcode.css';
import AutoSuggestion from './AutoSuggestion';
import cities from '../../../api/cities500.json';
import toast from 'react-hot-toast';
import { SketchPicker } from 'react-color';
import { Popover } from '@mui/material';
function Temperature({onAddTemprature,tempElement:textProps}) {
     const [obj,setObj]=useState({name:'',lat:'',lng:''})     
     const [colorAncherEl, setColorAncherEl] = useState(null);
     const [colorAncherEl1, setColorAncherEl1] = useState(null);
     const colorOpen = Boolean(colorAncherEl);
     const colorOpen1 = Boolean(colorAncherEl1);
     const colorId = colorOpen ? "simple-popover" : undefined;
     const colorId1 = colorOpen1 ? "simple-popover" : undefined;
    //  console.log('temp-ele->',textProps)
     const handleColorClick = (event) => {
      setColorAncherEl(event.currentTarget);
    };
    const handleColorClose = () => {
      setColorAncherEl(null);
    };
    const handleColorClick1 = (event) => {
      setColorAncherEl1(event.currentTarget);
    };
    const handleColorClose1 = () => {
      setColorAncherEl1(null);
    };
    const onChangeColorComplete = (color) => {
      // dispatch(setTextFontColor(color.rgb));
      textProps.onChangeTextFontColor(color.rgb,(properties) =>
        textProps.onSetAllProperties(properties));
    };
    const onChangeColorComplete1 = (color) => {
      // dispatch(setTextFontColor(color.rgb));
      textProps.onChangeBackgroundColor(color.rgb,(properties) =>
        textProps.onSetAllProperties(properties));
    };
     const _onAddWeather=()=>{
        if(obj.lat=='' || obj.lng==''){
            toast.error('Latitude Longitude requireq.');
            return;
        }
        obj.isSelected=false;
        onAddTemprature(obj);
     }

  //    const onChangeParams = (e) => {
  //     const { name, value } = e.target;
  //     const newSize = parseFloat(value);
      
  //     if (isNaN(newSize) || newSize <= 0) return; // Prevent invalid inputs
  
  //     let originalHeight = textProps.tempProps.height / textProps.tempProps.scaleY;
  //     let originalWidth = textProps.tempProps.width / textProps.tempProps.scaleX;
  
  //     let newScaleX = textProps.tempProps.scaleX;
  //     let newScaleY = textProps.tempProps.scaleY;
  //     let newWidth = textProps.tempProps.width;
  //     let newHeight = textProps.tempProps.height;
  
  //     if (name === "height") {
  //         newScaleY = Math.max(newSize / originalHeight, 0.01); // Prevent scale=0
  //         newHeight = newSize; // Update height
  //     } else if (name === "width") {
  //         newScaleX = Math.max(newSize / originalWidth, 0.01); // Prevent scale=0
  //         newWidth = newSize; // Update width
  //     }
  
  //     textProps.onChangeParams({
  //         ...textProps.tempProps, // Preserve other properties
  //         scaleX: parseFloat(newScaleX.toFixed(4)), // Maintain precision
  //         scaleY: parseFloat(newScaleY.toFixed(4)), // Maintain precision
  //         width: newWidth, // Ensure width updates
  //         height: newHeight, // Ensure height updates
  //         [name]: newSize, // Update input field
  //     });
  // };

  const onChangeParams = (e) => {
    const { name, value } = e.target;
    if (name == "scaleY") {
      textProps.onChangeParams({
        left: textProps.tempProps?.left,
        top: textProps.tempProps?.top,
        scaleY: +textProps.tempProps?.scaleY?.toFixed(1),
        scaleX: +textProps.tempProps?.toFixed(1),
        [name]: +value,
      });
      return;
    } else if (name == "scaleX") {
      textProps.onChangeParams({
        left: textProps.tempProps?.left,
        top: textProps.tempProps?.top,
        scaleY: +textProps.tempProps?.scaleY?.toFixed(1),
        scaleX: +textProps.tempProps?.scaleX?.toFixed(1),
        [name]: +value,
      });
      return;
    }
    textProps.onChangeParams({
      left: textProps.tempProps?.left,
      top: textProps.tempProps?.top,
      scaleY: +textProps.tempProps?.scaleY?.toFixed(1),
      scaleX: +textProps.tempProps?.scaleX?.toFixed(1),
      [name]: +value,
    });
  };

    //  console.log(textProps)
  return (
   <>
    <div className="qr-container">
    {/* <SearchField/> */}
   
    <div className='tool--wrapper'>
            <label htmlFor="barcodeValue" 
            >Search City:</label>
          <div className="text--tool">
          <AutoSuggestion placeholder="Search city by Name..." data={cities} onClear={()=>setObj({name:'',lat:'',lng:''})} onGetValue={(e)=>setObj({name:e.name,lat:e.lat,lng:e.lon})}  />
            {/* <input type="text" id="barcodeValue"
             style={{width:'100%',height:'100%',background:'white',border:'none',outline:'none'}}
              /> */}
    </div>
          </div>
          <div className="tool--wrapper">
          <label htmlFor="barcodeValue" 
            >Lat:</label>
            <div className='text--tool'>
                <input type={'text'} value={obj?.lat}/>
            </div>
          </div>
          <div className="tool--wrapper">
          <label htmlFor="barcodeValue" 
            >Lon:</label>
            <div className='text--tool'>
                <input type={'text'} value={obj?.lng}/>
            </div>
          </div>

{
  textProps?.tempProps?.isSelected && <>
          <div className="tool--wrapper">
      <p>Background Color:</p>
      <div className="text--tool">
        <button className="buttons" onClick={handleColorClick1}
        >
          <div style={{height:'15px',width:'15px',border:'1px solid gray',borderRadius:'50%',background:`${textProps?.tempProps?.background ?? 'black'}`}}></div>
          <span>{textProps?.tempProps?.background ?? 'black'}</span>
          
        </button>
        <Popover
          id={colorId1}
          open={colorOpen1}
          anchorEl={colorAncherEl1}
          onClose={handleColorClose1}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <SketchPicker
            color={textProps?.tempProps?.background ?? 'black'}
            onChangeComplete={onChangeColorComplete1}
          />
        </Popover>
      </div>

      </div>

 <div className="tool--wrapper">
      <p>Text Color:</p>
      <div className="text--tool">
        <button className="buttons" onClick={handleColorClick}
        >
          <div style={{height:'15px',width:'15px',border:'1px solid gray',borderRadius:'50%',background:`${textProps?.tempProps?.forecolor ?? 'white'}`}}></div>
          <span>{textProps?.tempProps?.forecolor ?? 'white'}</span>
          
        </button>
        <Popover
          id={colorId}
          open={colorOpen}
          anchorEl={colorAncherEl}
          onClose={handleColorClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <SketchPicker
            color={textProps?.tempProps?.forecolor ?? 'white'}
            onChangeComplete={onChangeColorComplete}
          />
        </Popover>
      </div>

      </div>
  </>
}


   <button
          style={{
            height: "40px",
            width: "100%",
            background:'var(--primary-color)',
            color:'var(--primary-forecolor)',
            border: "1px solid gray",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
          onClick={_onAddWeather}
        >
          
           Add Temperature
        </button>

        {/* <div className="tool--wrapper">
          <div className="text--tool">
            offsetX:
            <input
              type={"number"}
              value={textProps?.tempProps?.left}
              name={"left"}
              onChange={onChangeParams}
            />
          
          </div>
        </div>
        <div className="tool--wrapper">
          <div className="text--tool">
           
            offsetY:
            <input
              type={"number"}
              value={textProps?.tempProps?.top}
              name={"top"}
              onChange={onChangeParams}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            width:
            <input
              type={"number"}
              value={textProps?.tempProps?.width?.toFixed(2)}
              name={"width"}
              onChange={onChangeParams}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            height:
            <input
              type={"number"}
              value={textProps?.tempProps?.height?.toFixed(2)}
              name={"height"}
              onChange={onChangeParams}
            />
          </div>
        </div> */}

<div className="tool--wrapper">
          <div className="text--tool">
            offsetX:
            <input
              type={"number"}
              value={textProps.tempProps?.left?.toFixed(2)}
              name={"left"}
              onChange={onChangeParams}
            />
            {/* offsetY:<input value={playlist?.playlistProps?.offsetY?.toFixed(2)}/> */}
          </div>
        </div>
        <div className="tool--wrapper">
          <div className="text--tool">
            {/* offsetX:<input value={playlist?.playlistProps?.offsetX?.toFixed(2)} /> */}
            offsetY:
            <input
              type={"number"}
              value={textProps.tempProps?.top?.toFixed(2)}
              name={"top"}
              onChange={onChangeParams}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            ScaleX:
            <input
              type={"number"}
              step={"0.1"}
              min={"0.1"}
              value={+textProps.tempProps?.scaleX?.toFixed(1)}
              name={"scaleX"}
              onChange={onChangeParams}
              // onKeyDown={handleKeyPress}
            />
          </div>
          <span style={{ color: "gray", fontSize: "15px" }}>
            Width:
            {(
              textProps.tempProps?.width * textProps.tempProps?.scaleX
            ).toFixed(2)}
          </span>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            ScaleY:
            <input
              type={"number"}
              step={"0.1"}
              min={"0.1"}
              value={+textProps.tempProps?.scaleY?.toFixed(1)}
              name={"scaleY"}
              onChange={onChangeParams}
              // onKeyDown={handleKeyPress}
              // style={{fontSize:'10px',width:'30px',height:'100%',border:'1px solid gray',borderRadius:'10px'}}
            />
          </div>
          <span style={{ color: "gray", fontSize: "15px" }}>
            Height:
            {(
              textProps.tempProps?.height * textProps.tempProps?.scaleY
            ).toFixed(2)}
          </span>
        </div>


   </div>
   </>
  )
}

export default Temperature
