import React from "react";
import "../Text/text.css";
function ToolPlaylistOp({ playlist }) {
  const onChange = (e) => {
    const { value } = e.target;
    let obj = {};
    if (value == "fitXY") {
      obj.fitXY = true;
      obj.none = false;
    }
    if (value == "none") {
      obj.fitXY = false;
      obj.none = true;
    }
    playlist.onTypeChange(obj, (properties) => {
      playlist.onSetAllProperties(properties);
    });
  };

//   const onChangeParams = (e) => {
//     const { name, value } = e.target;
//     const newSize = parseFloat(value);
    
//     if (isNaN(newSize) || newSize <= 0) return; // Prevent invalid inputs

//     let originalHeight = playlist.playlistProps.height / playlist.playlistProps.scaleY;
//     let originalWidth = playlist.playlistProps.width / playlist.playlistProps.scaleX;

//     let newScaleX = playlist.playlistProps.scaleX;
//     let newScaleY = playlist.playlistProps.scaleY;
//     let newWidth = playlist.playlistProps.width;
//     let newHeight = playlist.playlistProps.height;

//     if (name === "height") {
//         newScaleY = Math.max(newSize / originalHeight, 0.01); // Prevent scale=0
//         newHeight = newSize; // Update height
//     } else if (name === "width") {
//         newScaleX = Math.max(newSize / originalWidth, 0.01); // Prevent scale=0
//         newWidth = newSize; // Update width
//     }

//     playlist.onChangeParams({
//         ...playlist.playlistProps, // Preserve other properties
//         scaleX: parseFloat(newScaleX.toFixed(4)), // Maintain precision
//         scaleY: parseFloat(newScaleY.toFixed(4)), // Maintain precision
//         width: newWidth, // Ensure width updates
//         height: newHeight, // Ensure height updates
//         [name]: newSize, // Update input field
//     });
// };


const onChangeParams = (e) => {
  const { name, value } = e.target;
  if (name == "scaleY") {

    playlist.onChangeParams({
      left: playlist.playlistProps.left,
      top: playlist.playlistProps.top,
      scaleY: +playlist.playlistProps.scaleY?.toFixed(1),
      scaleX: +playlist.playlistProps.scaleX?.toFixed(1),
      [name]: +value,
    });
    return;
  } else if (name == "scaleX") {
     playlist.onChangeParams({
      left: playlist.playlistProps.left,
      top: playlist.playlistProps.top,
      scaleY: +playlist.playlistProps.scaleY?.toFixed(1),
      scaleX: +playlist.playlistProps.scaleX?.toFixed(1),
      [name]: +value,
    });
    return;
  }
  playlist.onChangeParams({
    left: playlist.playlistProps.left,
    top: playlist.playlistProps.top,
    scaleY: +playlist.playlistProps.scaleY?.toFixed(1),
      scaleX: +playlist.playlistProps.scaleX?.toFixed(1),
    [name]: +value,
  });
};

  // const onChangeParams = (e) => {
  //   const { name, value } = e.target;
  //   let originalHeight=playlist.playlistProps.height/playlist.playlistProps.scaleY;
  //   let originalWidth=playlist.playlistProps.width/playlist.playlistProps.scaleX;
  //   console.log(parseFloat(+value/originalHeight).toPrecision(2),originalHeight,originalWidth)
  //   if (name == "height") {

  //     playlist.onChangeParams({
  //       left: playlist.playlistProps.left,
  //       top: playlist.playlistProps.top,
  //       height: playlist.playlistProps.height,
  //       width: playlist.playlistProps.width,
  //       scaleX: playlist.playlistProps.scaleX,
  //       scaleY: parseFloat(+value/originalHeight),
  //       [name]: +value,
  //     });
  //     return;
  //   } else if (name == "width") {
  //     playlist.onChangeParams({
  //       left: playlist.playlistProps.left,
  //       top: playlist.playlistProps.top,
  //       height: playlist.playlistProps.height,
  //       width: playlist.playlistProps.width,
  //       scaleX: parseFloat(+value/originalWidth),
  //       scaleY: playlist.playlistProps.scaleY,
  //       [name]: +value,
  //     });
  //     return;
  //   }
  //   playlist.onChangeParams({
  //     left: playlist.playlistProps.left,
  //     top: playlist.playlistProps.top,
  //     height: playlist.playlistProps.height,
  //     width: playlist.playlistProps.width,
  //     scaleX:playlist.playlistProps.scaleX,
  //     scaleY:playlist.playlistProps.scaleY,
  //     [name]: +value,
  //   });
  // };
  return (
    <>
      <div className={"text-container"}>
        <p>{playlist.playlistProps.type == "playlist" && "Playlist Scale"}</p>
        <p>{playlist.playlistProps.type == "nor-video" && "Video Scale"}</p>
        <p>{playlist.playlistProps.type == "nor-image" && "Image Scale"}</p>
        <div className="tool-wrapper">
          <p style={{ textTransform: "capitalize" }}>
            {playlist.playlistProps?.name?.substr(0, 20)}
          </p>
          {/* <p>{playlist.playlistProps.type == "playlist" && <>{playlist.playlistProps?.name?.substr(0,20)}</>}</p>
        <p>{playlist.playlistProps.type == "nor-video" && "Video Scale"}</p>
        <p>{playlist.playlistProps.type == "nor-image" && "Image Scale"}</p> */}
        </div>
        <div className={"tool--wrapper"}>
          <p>Fit XY</p>
          <div className={"text--tool"}>
            <span>Yes</span>{" "}
            <input
              type="radio"
              value={"fitXY"}
              checked={playlist?.playlistProps?.fitXY ?? false}
              onChange={onChange}
              name={"scaletype"}
            />
            <span>No</span>{" "}
            <input
              type="radio"
              value={"none"}
              checked={playlist?.playlistProps?.none ?? false}
              onChange={onChange}
              name={"scaletype"}
            />
          </div>
        </div>

        {/* <div className="tool--wrapper">
          <div className="text--tool">
            offsetX:
            <input
              type={"number"}
              value={playlist?.playlistProps?.left}
              name={"left"}
              onChange={onChangeParams}
            />
           
          </div>
        </div>
        <div className="tool--wrapper">
          <div className="text--tool">
           
            offsetY:
            <input
              type={"number"}
              value={playlist?.playlistProps?.top}
              name={"top"}
              onChange={onChangeParams}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            width:
            <input
              type={"number"}
              value={playlist?.playlistProps?.scaleX?.toFixed(1)}
              name={"width"}
              onChange={onChangeParams}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            height:
            <input
              type={"number"}
              value={playlist?.playlistProps?.scaleY?.toFixed(1)}
              name={"height"}
              onChange={onChangeParams}
            />
          </div>
        </div> */}


<div className="tool--wrapper">
          <div className="text--tool">
            offsetX:
            <input
              type={"number"}
              value={playlist.playlistProps?.left?.toFixed(2)}
              name={"left"}
              onChange={onChangeParams}
            />
            {/* offsetY:<input value={playlist?.playlistProps?.offsetY?.toFixed(2)}/> */}
          </div>
        </div>
        <div className="tool--wrapper">
          <div className="text--tool">
            {/* offsetX:<input value={playlist?.playlistProps?.offsetX?.toFixed(2)} /> */}
            offsetY:
            <input
              type={"number"}
              value={playlist.playlistProps?.top?.toFixed(2)}
              name={"top"}
              onChange={onChangeParams}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            ScaleX:
            <input
              type={"number"}
              step={'0.1'}
              min={'0.1'}
              value={+playlist.playlistProps?.scaleX?.toFixed(1)}
              name={"scaleX"}
              onChange={onChangeParams}
      // onKeyDown={handleKeyPress}
            />
          </div>
          <span style={{color:'gray',fontSize:'15px'}}>Width:{(playlist.playlistProps.width * playlist.playlistProps.scaleX).toFixed(2)}</span>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            ScaleY:
            <input
              type={"number"}
              step={'0.1'}
              min={'0.1'}
              value={+playlist.playlistProps?.scaleY?.toFixed(1)}
              name={"scaleY"}
              onChange={onChangeParams}
      // onKeyDown={handleKeyPress}
              // style={{fontSize:'10px',width:'30px',height:'100%',border:'1px solid gray',borderRadius:'10px'}}
            />
          </div>
          <span style={{color:'gray',fontSize:'15px'}}>Height:{(playlist.playlistProps.height * playlist.playlistProps.scaleY).toFixed(2)}</span>
        </div>


      </div>
    </>
  );
}

export default ToolPlaylistOp;
