import React from 'react'
import './qrcode.css';
import { QrCode } from 'lucide-react';
import SearchField from '../../../components/editor/SearchField';
import toast from 'react-hot-toast';
function QRCode({onAddQr,qrProps}) {
    
    
      function handleQRCodeSave() {
          
          // hideSidebar();
        }
        const _onAddQr=()=>{
        const qrCodeValue = document.getElementById('barcodeValue').value;
        if(qrCodeValue==''){
          toast.error('Value is required');
          return;
        }
        // generateQRCode(qrCodeValue);
        onAddQr(qrCodeValue);
    }

  //   const onChangeParams = (e) => {
  //     const { name, value } = e.target;
  //     const newSize = parseFloat(value);
      
  //     if (isNaN(newSize) || newSize <= 0) return; // Prevent invalid inputs
  
  //     let originalHeight = +qrProps?.qrProps?.height / +qrProps?.qrProps?.scaleY;
  //     let originalWidth = +qrProps?.qrProps?.width / +qrProps?.qrProps?.scaleX;
  
  //     let newScaleX = +qrProps?.qrProps?.scaleX;
  //     let newScaleY = +qrProps?.qrProps?.scaleY;
  //     let newWidth = +qrProps?.qrProps?.width;
  //     let newHeight = +qrProps?.qrProps?.height;
  
  //     if (name === "height") {
  //         newScaleY = Math.max(newSize / originalHeight, 0.01); // Prevent scale=0
  //         newHeight = newSize; // Update height
  //     } else if (name === "width") {
  //         newScaleX = Math.max(newSize / originalWidth, 0.01); // Prevent scale=0
  //         newWidth = newSize; // Update width
  //     }
  
  //     qrProps?.onChangeParams({
  //         ...qrProps?.qrProps, // Preserve other properties
  //         scaleX: parseFloat(newScaleX.toFixed(4)), // Maintain precision
  //         scaleY: parseFloat(newScaleY.toFixed(4)), // Maintain precision
  //         width: newWidth, // Ensure width updates
  //         height: newHeight, // Ensure height updates
  //         [name]: newSize, // Update input field
  //     });
  // };

  const onChangeParams = (e) => {
    const { name, value } = e.target;
    if (name == "scaleY") {
      qrProps.onChangeParams({
        left: qrProps.qrProps?.left,
        top: qrProps.qrProps?.top,
        scaleY: +qrProps.qrProps?.scaleY?.toFixed(1),
        scaleX: +qrProps.qrProps?.toFixed(1),
        [name]: +value,
      });
      return;
    } else if (name == "scaleX") {
      qrProps.onChangeParams({
        left: qrProps.qrProps?.left,
        top: qrProps.qrProps?.top,
        scaleY: +qrProps.qrProps?.scaleY?.toFixed(1),
        scaleX: +qrProps.qrProps?.scaleX?.toFixed(1),
        [name]: +value,
      });
      return;
    }
    qrProps.onChangeParams({
      left: qrProps.qrProps?.left,
      top: qrProps.qrProps?.top,
      scaleY: +qrProps.qrProps?.scaleY?.toFixed(1),
      scaleX: +qrProps.qrProps?.scaleX?.toFixed(1),
      [name]: +value,
    });
  };

  return (
   <>
   <div className="qr-container">
    {/* <SearchField/> */}
    <div className="tool--wrapper">
            <label htmlFor="barcodeType"
             >Barcode Type:</label>
   <div className="text--tool">
            <select id="barcodeType" 
            style={{width:'100%',height:'100%',background:'white',border:'none',outline:'none'}}
            // className="border border-gray-300 rounded p-2 w-full"
            >
              <option>Email</option>
              <option>Phone Number</option>
              <option>Url</option>
              <option>custom</option>
            </select>
          </div>
    </div>
    <div className='tool--wrapper'>
            <label htmlFor="barcodeValue" 
            >QR Code Value:</label>
          <div className="text--tool">
            <input type="text" id="barcodeValue"
             style={{width:'100%',height:'100%',background:'white',border:'none',outline:'none'}}
              />
    </div>
          </div>
   <button
          style={{
            height: "40px",
            width: "100%",
            background:'var(--primary-color)',
            color:'var(--primary-forecolor)',
            border: "1px solid gray",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
          onClick={_onAddQr}
        >
          {/* <QrCode /> */}
           Add QrCode
        </button>

        {/* <div className="tool--wrapper">
          <div className="text--tool">
            offsetX:
            <input
              type={"number"}
              value={qrProps?.qrProps?.left}
              name={"left"}
              onChange={onChangeParams}
            />
            
          </div>
        </div>
        <div className="tool--wrapper">
          <div className="text--tool">
            
            offsetY:
            <input
              type={"number"}
              value={qrProps?.qrProps?.top}
              name={"top"}
              onChange={onChangeParams}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            width:
            <input
              type={"number"}
              value={qrProps?.qrProps?.width?.toFixed(2)}
              name={"width"}
              onChange={onChangeParams}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            height:
            <input
              type={"number"}
              value={qrProps?.qrProps?.height?.toFixed(2)}
              name={"height"}
              onChange={onChangeParams}
            />
          </div>
        </div> */}

<div className="tool--wrapper">
          <div className="text--tool">
            offsetX:
            <input
              type={"number"}
              value={qrProps.qrProps?.left?.toFixed(2)}
              name={"left"}
              onChange={onChangeParams}
            />
            {/* offsetY:<input value={playlist?.playlistProps?.offsetY?.toFixed(2)}/> */}
          </div>
        </div>
        <div className="tool--wrapper">
          <div className="text--tool">
            {/* offsetX:<input value={playlist?.playlistProps?.offsetX?.toFixed(2)} /> */}
            offsetY:
            <input
              type={"number"}
              value={qrProps.qrProps?.top?.toFixed(2)}
              name={"top"}
              onChange={onChangeParams}
            />
          </div>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            ScaleX:
            <input
              type={"number"}
              step={"0.1"}
              min={"0.1"}
              value={+qrProps.qrProps?.scaleX?.toFixed(1)}
              name={"scaleX"}
              onChange={onChangeParams}
              // onKeyDown={handleKeyPress}
            />
          </div>
          <span style={{ color: "gray", fontSize: "15px" }}>
            Width:
            {(
              qrProps.qrProps?.width * qrProps.qrProps?.scaleX
            ).toFixed(2)}
          </span>
        </div>

        <div className="tool--wrapper">
          <div className="text--tool">
            ScaleY:
            <input
              type={"number"}
              step={"0.1"}
              min={"0.1"}
              value={+qrProps.qrProps?.scaleY?.toFixed(1)}
              name={"scaleY"}
              onChange={onChangeParams}
              // onKeyDown={handleKeyPress}
              // style={{fontSize:'10px',width:'30px',height:'100%',border:'1px solid gray',borderRadius:'10px'}}
            />
          </div>
          <span style={{ color: "gray", fontSize: "15px" }}>
            Height:
            {(
              qrProps.qrProps?.height * qrProps.qrProps?.scaleY
            ).toFixed(2)}
          </span>
        </div>


   </div>
   </>
  )
}

export default QRCode
